.App {
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.blur-image {
  filter: blur(5px); /* Apply the blur effect */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.indicator {
  background: #3b7ddd;
  box-shadow: 0 0.1rem 0.2rem rgb(0 0 0 / 5%);
  border-radius: 50%;
  display: block;
  height: 18px;
  width: 18px;
  padding: 1px;
  position: absolute;
  top: 0;
  right: -8px;
  text-align: center;
  transition: top 0.1s ease-out;
  font-size: 0.675rem;
  color: #fff;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.js-simplebar {
  position: sticky !important;
  top: 0;
  left: 0;
  overflow-y: auto !important;
}

@media (min-width: 992px) {
  .navbar-collapse .collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
}
.sidebar-content {
}

.modal-close-icon-x::before {
  content: "\00d7";
  font-size: 1.4em;
}
