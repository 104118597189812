.register-form {
  justify-content: center;
}
.rlogo-image {
  box-shadow: 0 -5px 3px -3px rgb(21, 13, 13), 0 5px 3px -3px rgb(22, 16, 16);
  border-radius: 5%;
  width: 200px;
  height: 110px;
  animation: rlogo 8s ease infinite;
}

@keyframes rlogo {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
.rlogo-image:hover {
  transform: none;
  animation: none;
  /* transform:scale(1.3);
   transition-timing-function: ease-in-out;
   transition-duration:1s; */
}

/* #r-fa-user {
  font-size: 100px;
  color: cornsilk;
  padding-left: 38%;
  padding-top: 21px;
} */
.r-text {
  text-transform: uppercase;
  font-weight: 600;
  color: whitesmoke;
}
/* #r-fa-card {
  font-size: 100px;
  color: #fdbb94fa;
  padding-left: 38%;
  padding-top: 21px;
} */

.user-sec {
  /* background-color: #2d9d88; */
  background-color: #8bb7f0;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 8px black;
  margin-right: 30px;
}
.seller-sec {
  background-color: #05739d;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 8px black;
  margin-left: 30px;
}

.user-sec:hover h3,
.seller-sec:hover h3 {
  color: #f58723;
}
.user-sec:hover,
.seller-sec:hover {
  box-shadow: 0px 0px 5px black;
  transform: scale(1.02);
  transition-duration: 6ms;
}
.user-sec:hover .re-sign {
  display: inline;
}
.individual-user,
.business-user {
  height: 20vh;
  /* width:100%; */
  border-top-left-radius: 6%;
  border-top-right-radius: 6%;
}

@media screen and (max-width: 920px) {
  .register-form {
    flex-direction: column;
  }

  .user-sec {
    margin-top: -50px;
    height: 150px;
    margin-left: 40px;
  }
  .seller-sec {
    height: 150px;
    margin-left: 40px;
    margin-top: 10px;
  }

  .r-text {
    font-weight: 600;
    font-size: 15px;
  }
  .individual-user,
  .business-user {
    height: 14vh !important;
  }
}

/* new register form */
.__box_user {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #8bb7f0; */
  margin: 20px 0;
  padding: 10px;
  /*   width: 100%; */
  min-height: 230px;
  border: 2px #ccc solid;
  color: #fff;
  border-radius: 20px;
}

.__box_user:hover {
  box-shadow: 0px 0px 5px rgb(80, 67, 67);
  /* transform: scale(1.02); */
  transition-duration: 6ms;
  /* color: #f58723; */
  color: #21170e;
}
