.contactcontainer {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

#ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

/* .wrap {
    box-shadow: 0 0 20px 0 rgba(57, 82, 163, 0.7);
  } */

.wrap > * {
  padding: 1em;
}

/* ------------------- */
/* COMPANY INFORMATION */
/* ------------------- */

.company-info {
  background: #fafafa;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.company-info h3,
.company-info #ul {
  text-align: center;
  margin: 0 0 1rem 0;
}

/* ------- */
/* CONTACT */
/* ------- */

.contact {
  background: #fafafa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* ---- */
/* FORM */
/* ---- */

.contact #contact-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.contact-form label {
  display: block;
  color: black;
  font-size: 16px;
}

.contact #contact-form p {
  margin: 0;
}

.contact #contact-form .full {
  grid-column: 1 / 3;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1em;
  border: solid 1px #151927;
  border-radius: 4px;
}
.contact-form button {
  width: 100%;
  padding: 1em;
  font-size: 16px;
  border-radius: 4px;
  background-color: #34d399 !important;
  border: none;
}
.contact-form button:hover {
  /* background-color: #7ed3b4 !important; */
  color: whitesmoke;
}

.contact #contact-form textarea {
  resize: none;
}

.contact #contact-form button {
  background: #34d399;
  border: 0;
  color: #e4e4e4;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.contact #contact-form button:hover,
.contact #contact-form button:focus {
  background: #20c997;
  color: #ffffff;
  outline: 0;
  transition: background-color 2s ease-out;
}

/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@media only screen and (min-width: 700px) {
  .wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .wrap > * {
    padding: 2em;
  }

  .company-info {
    border-radius: 4px 0 0 4px;
  }

  .contact {
    border-radius: 0 4px 4px 0;
  }

  .company-info h3,
  .company-info #ul,
  .brand {
    text-align: left;
  }
}
.map-responsive {
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  height: 0;
}
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.contacts-container {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 20px;
}

.contact-from {
  width: 50%;
  display: flex;
  align-items: center;
}

.location-details {
  width: 50%;
}

.contact-item {
  margin: 15px 0px;
  gap: 15px;
}

.map-location {
  width: 100%;
  height: 100%;
}

.contact-info {
  margin-top: 20px;
}

@media only screen and (max-width: 700px) {
  .contacts-container {
    flex-direction: column;
  }

  .contact-item div {
    width: 50%;
  }

  .location-details {
    margin-top: 40px;
    width: 100%;
    height: 400px;
  }
}
