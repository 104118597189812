/* #cf{
    border-bottom:1px solid gray;
} */
.h-drop .dropdown-menu.show {
  overflow-y: scroll;
  height: 300px;
  z-index: 166600;
}
#bf {
  border-top: 2px solid #3498db;
  border-left: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  border-right: 2px solid #3498db;
}

#cf {
  background-color: white;
}

#input-group-dropdown-12 {
  background-color: #319ffb;
  font-weight: 600;
  color: white;
}

.dashboard-home {
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  padding: 5px;
  /* background: linear-gradient(to right, #ff5f43, #f35858 50%, #319ffb 50%); */
  color: #319ffb;
  background-clip: text;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease; */
}
.dashboard-home:hover {
  /* background-position: 0 100%; */
  color: #ff5f43;
}

/* #bf:focus {
  border-top: 2px solid orangered;
  border-left: 2px solid orangered;
  border-bottom: 2px solid orangered;
  border-right: 2px solid orangered;
} */

#sbp {
  border-top: 2px solid #3498db;
  border-right: 2px solid #3498db;
  border-bottom: 2px solid #3498db;
  border-left: 2px solid #3498db;
  background-color: #3498db;
  border-bottom-right-radius: 50%;
}
#l-text {
  text-align: justify;
}
.cart-baskets {
  color: white;
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  bottom: 20px;
  right: 26px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: red;
}
.navbar {
  border-bottom: 0;
  box-shadow: none;
}
#nl {
  display: flex;
  /* flex-direction:column; */
}
#nl:hover #fass {
  color: orangered;
}
#nl:hover .lr-text {
  color: orangered;
}
.lr-text {
  font-weight: bold;
}
#fass {
  font-size: 12px;
  color: rgb(218, 230, 230);
}

.fli a {
  margin-left: 10px;
}
.fli a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  #fass {
    display: none;
  }
  .cart-baskets {
    top: 0px;
  }

  #cf {
    height: 175px;
  }
  .__drop {
    margin-left: -100px !important;
    margin-top: 40px;
  }

  .__cart-icon {
    margin-right: -55px !important;
    margin-left: 30px !important;
  }
}

@media only screen and (min-width: 492px) and (max-width: 615px) {
}
