.star-rating {
  color: #fdcc0d;
}

.search-img {
  height: 220px;
}

.search-sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.rs-card:hover{
 transform: scale(1.09);
transition-duration:1s;
}