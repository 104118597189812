/* footer.footer {
  padding: 1rem 0.875rem;
  direction: ltr;
  clear: both;
}


.socialbtn {
  background-color: #8ad4e9;
  margin-left: 87px;
  height: 149px;
  width: 30.333333%;
}
#row {
  background-color: #95daee;
}
#btn {
  background-color: #95daee;
  margin-left: -25px;
  margin-top: 35px;
  height: 61px;
  width: 0px;
}
.fbtn {
  background-color: #34d399;
  color: white;
  text-transform: uppercase;
  height: 30px;
}
.para {
  width: 226px;
  line-height: 40px;
  font-size: 108.333%;
  font-weight: 700;
  padding-right: 70px;
  white-space: nowrap;
  color: white;
  margin-left: 21px;
  text-transform: uppercase;
}

#row .form1 {
  width: 220px;
  margin-left: 250px;
  height: 10px;
}
.col5 {
  margin-top: 31px;
}
.fabs {
  margin-top: 10px;
  margin-left: 7px;
  margin-right: 20px;
  color: white;
}
.social .bt {
  color: #fff;
  font-size: 15px;
  display: inline-flex;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  margin-top: 34px;
  margin-right: 3px;
  text-decoration: none;
}
.social .bt:hover {
  background-color: #34d399;
}
#bottom {
  background-color: #dee2e6;
  color: black;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}
#firstbottom {
  height: 271px;
  background-color: #6c757d1a;
}
.row-id {
  width: 435px;
  margin-left: 75px;
}
#firstbottom .h6 {
  margin-right: 180px;
  padding-top: 36px;
}
.fass {
  margin-left: 78px;
  width: 27px;
  height: 26px;
  background: #4a5678;
  color: white;
  text-align: center;
  border-radius: 50%;
  padding-top: 6px;
}
#p {
  text-align: left;
}

#btnf {
  color: black;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  text-align: left;
  padding-top: 31px;
  padding-left: 26px;
}
#list li {
  padding-top: 10px;
}
#list li a {
  color: black;
}
#list li a:hover {
  color: #ff5500;
  text-decoration: none;
}

@media screen and (max-width: 615px) {
  footer.footer {
    display: flex;
    flex-direction: column;
    width: 492px;
  }
  #bottom,
  .main-container {
    width: 492px;
  }
  .socialbtn {
    width: 492px;
    margin-left: 0px;
  }
  .social .bt {
    font-size: 35px;
    display: inline-flex;
    width: 82px;
    height: 58px;
  }
  .para {
    line-height: 1px;
    font-size: 100%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 109px;
    margin-top: -32px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 218px;
    margin-left: 0px;
  }
  #row {
    width: 490px;
  }
  #btn {
    margin-left: -53px;
  }
  .row-id {
    margin-left: -2px;
    width: 490px;
  }
}

@media only screen and (min-width: 615px) and (max-width: 768px) {
  footer.footer {
    display: flex;
    flex-direction: column;
    width: 737px;
  }
  #bottom,
  .main-container {
    width: 737px;
  }
  .socialbtn {
    width: 737px;
    margin-left: 0px;
  }
  .social .bt {
    font-size: 35px;
    display: inline-flex;
    width: 82px;
    height: 58px;
  }
  .para {
    line-height: 1px;
    font-size: 100%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 109px;
    margin-top: -32px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 280px;
    margin-left: 111px;
  }
  #row {
    width: 737px;
  }
  #btn {
    margin-left: -53px;
  }
  .row-id {
    margin-left: -2px;
    width: 737px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 998px) {
  footer.footer {
    display: flex;
    flex-direction: column;
  }
  #bottom,
  .main-container {
    width: 770px;
  }
  .socialbtn {
    width: 165px;
    margin-left: 0px;
  }
  .social .bt {
    font-size: 15px;
    display: inline-flex;
    width: 27px;
    height: 37px;
  }
  .para {
    line-height: 1px;
    font-size: 77.333%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 162px;
    margin-top: -19px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 255px;
    margin-left: -2px;
  }
  .mb-md-0 {
    margin-left: 10px;
  }
  #row {
    width: 770px;
  }
  #btn {
    margin-left: -53px;
  }
  .row-id {
    margin-left: -2px;
    width: 770px;
  }
}
@media only screen and (min-width: 998px) and (max-width: 1179px) {
  .para {
    line-height: 1px;
    font-size: 67.333%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 162px;
    margin-top: -19px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 255px;
    margin-left: 135px;
  }
} */

/* ----------------------------------- ... ---------------------------------- */
.user__footer {
  background-color: rgb(35 64 122) !important;
  font-weight: 500;
  font-size: initial;
}
footer {
  margin-top: 130px;
}
.get-in-touch {
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
}
.footer-heading h6 {
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  color: #ffc107;
  text-align: center;
}
.footer-heading h6:hover {
  color: rgb(93, 213, 113);
}
.footer-heading p {
  /* text-align: left; */
  margin-left: 8px;
  font-size: 17px;
  cursor: pointer;
  padding-top: 0px;
}
.footer-heading p a,
.f-anchor,
.anchoradd {
  color: white !important;
}
.anchor-hover:hover,
.footer-heading p:hover {
  color: rgb(93, 213, 113);
  text-decoration: underline;
}
.footer-heading p a:hover,
.f-anchor:hover,
.anchoradd:hover {
  color: rgb(93, 213, 113) !important;
  text-decoration: underline;
}
.footer-social {
  padding: 6px;
  margin-left: 20px;
  box-shadow: 0 0 8px #213050;
  background-color: #ffffff;
  border-radius: 5px;
}
.footer-social.fa-twitter {
  color: #00acee;
}
.footer-social.fa-instagram {
  color: #bc2a8d;
}
.footer-social.fa-whatsapp {
  color: #128c7e;
}
.footer-social.fa-facebook:hover {
  border: none;
  transform: scale(1.5);
  transition: 1s;
  color: rgb(59, 136, 204);
}

.footer__linear__gradient {
  /* background-image: linear-gradient(45deg, #673ab7, transparent) !important; */
  background-image: linear-gradient(45deg, #9c27b0, transparent) !important;
}

.footer-social.fa-twitter:hover {
  border: none;
  transform: scale(1.5);
  transition: 1s;
  color: #00acee;
}
.footer-social.fa-instagram:hover {
  border: none;
  transform: scale(1.5);
  transition: 1s;
  color: #bc2a8d;
}
.footer-social.fa-viber:hover,
.footer-social.fa-whatsapp:hover {
  border: none;
  transform: scale(1.5);
  transition: 1s;
}
@media only screen and (max-width: 768px) {
  .get-touch .text-capitalize {
    font-size: large;
    /* margin-top: -20px; */
  }
  .anchoradd {
    margin-left: 4rem;
  }
  .f-section {
    flex-direction: column;
  }

  .get-in-touch {
    text-align: center;
    padding-bottom: 0rem;
    padding-top: 2rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .__icons {
    margin-top: 1.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 912px) {
  .f-section {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 768px) {
  .faq__video_iframe iframe {
    width: 100%;
    height: 400px;
  }
}
