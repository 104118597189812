body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-tabs__tab--selected {
  border-color: #d63d3d !important;
}
.quote__modal__img {
  max-height: 80px !important;
}
.audio-react-recorder__canvas {
  background-color: #e6e5e5;
  border-radius: 6px;
}
