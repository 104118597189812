#s-form {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
}
#k-logo {
  box-shadow: 0px 0px 18px rgb(211, 213, 226);
  width: 80px;
  height: 80px;
  border-bottom-left-radius: 20%;
  border-top-right-radius: 20%;
  /* margin: 0px 20px; */
  margin-left: 4.2rem;
  /* animation: rotate 28.5s linear infinite;  */
}
#k-logo:hover {
  border-top-left-radius: 20%;
  border-bottom-right-radius: 20%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 0%;
}
#d-d1 {
  overflow-y: scroll;
}
.store {
  float: right;
  font-size: 12px;
  color: rgb(22 177 251);
  margin-right: 30px;
  margin-top: 15px;
  width: 100%;
}
.user-profile-name {
  white-space: nowrap;
  margin-top: 7px;
}

.cart {
  /* margin-right:2rem */
}
.account-drop {
  background: #09195c;
  z-index: 2600;
}
.account-drop .drop-text {
  color: white;
}
.account-drop .drop-text:hover {
  color: #eb3007;
}
.cart-basket {
  /* background-color: yellow;
  border-radius: 50%;
  padding:2px;
    font-size: 15px;
    position: absolute;
    bottom: 24px;
    height: 15px;
    width: 15px;
    right: 32px; */
  color: white;
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  bottom: 24px;
  right: 33px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: red;
}

.wishlist-basket {
  display: flex;
  cursor: pointer;
}

.wishlist-basket span {
  margin-top: -10px;
  margin-left: -5px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: red;
  color: white;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}

.search-input {
  width: 100%;
}

.btn-stylee {
  background-color: rgb(72 93 137);
}

.btn-stylee:hover {
  background-color: rgb(198, 211, 240);
}

.search-dropdown .dropdown-menu.show {
  overflow-y: scroll;
  height: 300px;
}

/* blink button */
.blinkbtn {
  background-color: #34d399;
  border: none;
  color: #ffffff;
  font-size: 15px;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  height: 40px;
  width: 100px;
  animation: glowing 1500ms infinite;
}
.blinkbtn:hover {
  background-color: rgb(49, 5, 17);
}
.r-button {
  background-color: #34d399;
  border: none;
  color: #ffffff;
  font-size: 15px;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  height: 40px;
  width: 100px;
}
.r-button:hover {
  color: black;
}
.cart-login {
  justify-content: space-around;
}
.cart-login1 {
  justify-content: flex-end;
}
.us-profile {
  /* background-color: #131a4b;
 box-shadow: 0px 0px 4px white;
    border-radius: 5px; */
  padding: 7px 0px;
  color: white;
  width: auto;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
}
/* .us-profile:hover{
  box-shadow: 0px 0px 4px white;
} */
.user-profile-image {
  border-radius: 100%;
  padding-left: -5rem;
}
.user-profile-name {
  position: relative;
  left: 5px;
  top: 4px;
}

@keyframes glowing {
  0% {
    background-color: #34d399;
    box-shadow: 0 0 3px #34d399;
  }
  50% {
    background-color: #34d399;
    box-shadow: 0 0 40px #34d399;
  }
  100% {
    background-color: #34d399;
    box-shadow: 0 0 3px #34d399;
  }
}
@media only screen and (min-width: 570px) and (max-width: 888px) {
  .store {
    font-size: 10px;
    margin-right: 44px;
  }
  .blinkbtn {
    margin-right: 89px;
  }
}
@media only screen and(min-width:476px) and (max-width: 570px) {
  .r-button {
    /* margin-right:-1rem; */
    /* margin-left:-1rem; */
    width: 100px;
  }
  .blinkbtn {
    margin-right: -4rem;
    width: 100px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 476px) {
  .k-logos {
    margin-left: 2rem;
    margin-top: 1rem;
  }
  #k-logo {
    /* margin-left: -219px !important; */
  }
  .cart-login1 {
    /* margin-left: 14rem; */
  }
  .r-button,
  .blinkbtn {
    width: 5rem !important;
  }
  .login-sign,
  .fa-sign-in-alt {
    display: none;
  }
  .cart {
    margin-top: 2px;
    margin-left: 3rem;
    right: 80px !important;
  }
  .user-profile-image {
    margin-left: -5rem;
  }
  .user-profile-name {
    font-size: 10px;
    margin-left: 0rem;
  }
  .account-drop {
    margin-left: -5rem;
    line-height: 0px;
    font-size: 10px;
  }
}
@media only screen and (max-width: 768px) {
  #k-logo {
    position: relative;
    left: 20px;
    top: 5px;
    margin-left: -20px;
  }
  .search-input {
    width: 187px;
  }
  .b-b {
    display: none;
  }
  .r-button,
  .blinkbtn {
    width: 6rem;
    margin-left: 1rem;
  }

  #s-form {
    /* width: 87%; */
  }
  .cart-login {
    margin-left: -20px;
  }

  .wishlist-basket {
    margin-left: 1rem;
  }
  .cart {
    color: white !important;
    margin-top: 2px;
    margin-left: 2.5rem;
    right: 30px;
  }
  .store .fa-user {
    display: none;
  }
  .cart-basket {
    top: 1px;
  }
}
