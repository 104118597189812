.active_nav0 {
  color: #e9ecef !important;
  border-left-color: #3bdd4e !important;
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.0875) 50%,
    transparent
  ) !important;
  /* border-left-color: #3b7ddd ! important; */
}

.active_nav__admin_1 {
  font-weight: 400 !important;
  border-left: 0 !important;
  color: #51e1bf !important;
  background: transparent !important;
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.0875) 50%,
    transparent
  ) !important;
}
.simplebar-content-wrapper {
  direction: inherit;
  overflow: scroll;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.sidebar {
  transition: all 0.7s ease-out;
}
