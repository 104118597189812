/* footer.footer {
  padding: 1rem 0.875rem;
  direction: ltr;
  clear: both;
}

#home__footer {
  background-color: #005fad !important;
}

.socialbtn {
  background-color: #8ad4e9;
  margin-left: 87px;
  height: 149px;
  width: 30.333333%;
}
#row {
  background-color: #95daee;
}
#btn {
  background-color: #95daee;
  margin-left: -25px;
  margin-top: 35px;
  height: 61px;
  width: 0px;
}
.fbtn {
  background-color: #34d399;
  color: white;
  text-transform: uppercase;
  height: 30px;
}
.para {
  width: 226px;
  line-height: 40px;
  font-size: 108.333%;
  font-weight: 700;
  padding-right: 70px;
  white-space: nowrap;
  color: white;
  margin-left: 21px;
  text-transform: uppercase;
}

#row .form1 {
  width: 220px;
  margin-left: 250px;
  height: 10px;
}
.col5 {
  margin-top: 31px;
}
.fabs {
  margin-top: 12px;
  margin-left: 13px;
  margin-right: 20px;
  color: white;
}
.social .bt {
  color: #fff;
  font-size: 15px;
  display: inline-flex;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  margin-top: 34px;
  margin-right: 3px;
  text-decoration: none;
}
.social .bt:hover {
  background-color: #34d399;
}
#bottom {
  background-color: #dee2e6;
  color: black;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}
#firstbottom {
  height: 271px;
  background-color: #6c757d1a;
}
.row-id {
  width: 435px;
  margin-left: 75px;
}
#firstbottom .h6 {
  margin-right: 180px;
  padding-top: 36px;
}
.fass {
  margin-left: 78px;
  width: 27px;
  height: 26px;
  background: #4a5678;
  color: white;
  text-align: center;
  border-radius: 50%;
  padding-top: 6px;
}
#p {
  text-align: left;
}

#btnf {
  color: black;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  text-align: left;
  padding-top: 31px;
  padding-left: 26px;
}
#list li {
  padding-top: 10px;
}
#list li a {
  color: black;
}
#list li a:hover {
  color: #ff5500;
  text-decoration: none;
}

@media screen and (max-width: 615px) {
  footer.footer {
    display: flex;
    flex-direction: column;
    width: 492px;
  }
  #bottom,
  .main-container {
    width: 492px;
  }
  .socialbtn {
    width: 492px;
    margin-left: 0px;
  }
  .social .bt {
    font-size: 35px;
    display: inline-flex;
    width: 82px;
    height: 58px;
  }
  .para {
    line-height: 1px;
    font-size: 100%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 109px;
    margin-top: -32px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 218px;
    margin-left: 0px;
  }
  #row {
    width: 490px;
  }
  #btn {
    margin-left: -53px;
  }
  .row-id {
    margin-left: -2px;
    width: 490px;
  }
}

@media only screen and (min-width: 615px) and (max-width: 768px) {
  footer.footer {
    display: flex;
    flex-direction: column;
    width: 737px;
  }
  #bottom,
  .main-container {
    width: 737px;
  }
  .socialbtn {
    width: 737px;
    margin-left: 0px;
  }
  .social .bt {
    font-size: 35px;
    display: inline-flex;
    width: 82px;
    height: 58px;
  }
  .para {
    line-height: 1px;
    font-size: 100%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 109px;
    margin-top: -32px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 280px;
    margin-left: 0px;
  }
  #row {
    width: 737px;
  }
  #btn {
    margin-left: -53px;
  }
  .row-id {
    margin-left: -2px;
    width: 737px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 998px) {
  footer.footer {
    display: flex;
    flex-direction: column;
  }
  #bottom,
  .main-container {
    width: 770px;
  }
  .socialbtn {
    width: 165px;
    margin-left: 0px;
  }
  .social .bt {
    font-size: 15px;
    display: inline-flex;
    width: 37px;
    height: 37px;
  }
  .para {
    line-height: 1px;
    font-size: 100%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 162px;
    margin-top: -19px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 255px;
    margin-left: -2px;
  }
  .mb-md-0 {
    margin-left: 10px;
  }
  #row {
    width: 770px;
  }
  #btn {
    margin-left: -53px;
  }
  .row-id {
    margin-left: -2px;
    width: 770px;
  }
}
@media only screen and (min-width: 998px) and (max-width: 1179px) {
  .para {
    line-height: 1px;
    font-size: 100%;
    font-weight: 400;
    padding-right: 53px;
    margin-left: 162px;
    margin-top: -19px;
  }
  #row .form1 {
    margin-top: 0px;
    width: 255px;
    margin-left: 135px;
  }
} */

#home__footer {
  bottom: 0;
}
