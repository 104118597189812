#grad1 {
  background-image: linear-gradient(25deg, #5e50a9ce 10%, lightblue 100%);
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

.fs-title {
  font-size: 35px;
  color: black;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: rgb(0, 0, 0);
}

#sfont {
  font-size: 20px;
  color: #090707;
  margin-left: 20px;
}

#progressbar li.active:after {
  background: rgb(107, 28, 28);
}
#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
  cursor: pointer;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f0c0";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f023";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f0ee";
}

#progressbar li:before {
  width: 150px;
  height: 150px;
  line-height: 45px;
  display: block;
  font-size: 55px;
  color: #e3eaf8;
  background: rgb(18, 20, 90);
  border-radius: 50%;
  margin: 10px auto 40px auto;
  padding: 45px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 4px;
  background: rgb(107, 28, 28);
  position: absolute;
  left: 0;
  top: 75px;
  z-index: -1;
}

#stepbut {
  margin-top: -60px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #34d399;
  font-size: 17px;
  color: white;
  box-shadow: 0px 2px 3px rgb(38, 36, 36);
}
#stepbut:hover {
  background-color: #3dc996;
  box-shadow: 0px 0px 5px black;
  color: black;
  transform: translate(0px, -1px);
}
/* .step-card{
  box-shadow: 0px 0px 10px;
} */

#progressbar li:hover #sfont {
  color: rgb(107, 28, 28);
}
#progressbar li:hover:before {
  background-color: #1f9cc9;
  color: #aabde1;
  transform: scale(1.06);
}
#progressbar li:hover:after {
  background-color: #0026ff;
}
@media only screen and (max-width: 768px) {
  #progressbar {
    width: 334%;
    display: flex;
    flex-direction: column;
  }
}
/* -------------------------------------------------------------------------- */
/*                                   Button                                   */
/* -------------------------------------------------------------------------- */

.lets__get_start_button_wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lets__get_start_button {
  min-width: 300px;
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #4fd1c5;
  background: linear-gradient(
    90deg,
    rgba(129, 230, 217, 1) 0%,
    rgba(79, 209, 197, 1) 100%
  );
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(79, 209, 197, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.lets__get_start_button::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #00ffcb;
  box-shadow: 0 0 60px rgba(0, 255, 203, 0.64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.lets__get_start_button:hover,
.lets__get_start_button:focus {
  color: #313133;
  transform: translateY(-6px);
}

.lets__get_start_button:hover::before,
.lets__get_start_button:focus::before {
  opacity: 1;
}

.lets__get_start_button::after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.lets__get_start_button:hover::after,
.lets__get_start_button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}
