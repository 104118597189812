.qty-btn {
  width: 22px;
  height: 26px;
  background-color: #34d399;
  border: none;
}
.qtyp {
  border: 2px solid #34d399;
  width: 60px;
  height: 26px;
  /* padding: 2px 0 0 9px; */
}
.stars span {
  font-size: 13px;
}

.specs {
  padding: 10px;
  /* border: 1px solid grey; */
  /* border-radius: 10px; */
  /* box-shadow: 5px 5px 10px whitesmoke; */
}

.specs-items div {
  padding: 3px 0;
  display: flex;
}

.specs-item div:first-child {
  width: 150px;
}

.selected-colors {
  margin-left: -70px;
  margin-top: 20px;
}

hr {
  color: #d4d4d4;
}

.buttons .cart:hover,
.buttons .order-button:hover {
  background-color: #23eba2;
  color: #fff;
}
.buttons .cart,
.buttons .order-button {
  width: 130px;
  color: white;
  height: 30px;
  background-color: #34d399;
  border: none;
}

.react-slider__areaZoom .react-slider__lens {
  position: absolute;
  z-index: 3;
  border: 1px solid #d4d4d4;
  width: 100px;
  height: 100px;
  background-color: chartreuse;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.react-slider {
  margin-left: 20px;
  width: 385px;
}
#sp-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: max-content;
  padding: 20px;
}

.input-radio-btn {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .react-slider {
    width: 385px;
  }
}
@media (min-width: 992px) {
  #cspp {
    margin-left: 180px;
  }
  .react-slider {
    width: 385px;
  }
}
@media (min-width: 1200px) {
  #cspp {
    margin-left: -20px;
  }
  .description-part {
    margin-left: 17rem;
  }
}
/*  */
@media (min-width: 1100px) {
  .description-part {
    margin-left: 6rem;
  }
}
@media (min-width: 1440px) {
  .description-part {
    margin-left: 14rem;
  }
}
