.offer {
  /* background-image: linear-gradient(-25deg, #5e50a9 47%, lightblue 67%);
  height: auto;
  width: auto; */
  /* padding-top: 100px; */
}
.offerss {
  /* margin-top:60px; */
  padding: 40px 0px;
  /* background-color: #003a35a3; */
  /* background-color: rgb(99, 81, 206); */
  height: auto;
  background-image: linear-gradient(-25deg, #5e50a9 47%, lightblue 67%);
}
.offer1,
.offer2 {
  border-right: 1px solid rgb(247, 250, 247);
}

.offer1,
.offer2,
.offer3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.offer-text:hover {
  color: orange;
}
.offer1:hover .offer-icon,
.offer2:hover .offer-icon,
.offer3:hover .offer-icon {
  transform: scale(1.2);
}
.offer-icon {
  width: 50px;
  background-color: white;
  box-shadow: -5px 5px 5px rgb(19, 18, 18);
  /* border-radius: 5px; */
  transform-origin: top right;
  transform-origin: bottom left;
  transform: skew(-10deg, -10deg);
}

.offer-text {
  color: whitesmoke;
  font-size: 17px;
  font-weight: 600;
  padding-left: 10px;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media only screen and (max-width: 615px) {
  .offerss {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (max-width: 615px) {
  .three_step_offer_ {
    font-size: 15px;
  }
}
/* @media only screen and (min-width: 720px) {
  .three_step_offer_ {
    font-size: 1px;
  }
} */
