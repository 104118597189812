

label.radio input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: pointer;
}

label.radio span {
    padding: 8px 43px;
    /* border: 1.5px solid #8F7AB2; */
    display: inline-block;
    color: #68696B;
    border-radius: 3px;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
background-color:gainsboro;
}

label.radio input:checked+span {
    border-color: #5dbad1;
    background:rgb(26, 173, 241);
    color: black
}

#btrw {
    border-radius: 3px
}

#btrw span {
    font-size: 14px !important;
    color:black;
}

@media screen and (max-width: 767px) {
    .radio {
        margin: 5px 0
    }
}