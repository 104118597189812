.table-wishlist table {
  width: 100%;
}
.table-wishlist thead {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 5px;
}
.table-wishlist thead tr th {
  padding: 8px 0 18px;
  color: #484848;
  font-size: 15px;
  font-weight: 400;
}
.table-wishlist tr td {
  padding: 25px 0;
  vertical-align: middle;
}
.table-wishlist tr td .img-product {
  width: 72px;
  float: left;
  margin-left: 8px;
  margin-right: 31px;
  line-height: 63px;
}
.table-wishlist tr td .img-product img {
  width: 100%;
}
.table-wishlist tr td .name-product {
  font-size: 15px;
  color: #484848;
  padding-top: 8px;
  line-height: 24px;
  width: 50%;
}
.table-wishlist tr td.price {
  font-weight: 600;
}
.table-wishlist tr td .quanlity {
  position: relative;
}

.wishlist__total {
  font-size: 24px;
  font-weight: 600;
  color: #8660e9;
}

.round-black-btn {
  border-radius: 25px;
  background: #212529;
  color: #fff;
  padding: 5px 20px;
  display: inline-block;
  border: solid 2px #212529;
  transition: all 0.5s ease-in-out 0s;
  cursor: pointer;
  font-size: 14px;
}
.round-black-btn:hover,
.round-black-btn:focus {
  background: transparent;
  color: #212529;
  text-decoration: none;
}

.d-block {
  display: block;
}
.custom-form label {
  font-size: 14px;
  line-height: 14px;
}
.pretty.p-default {
  margin-bottom: 15px;
}
.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: #8660e9;
}
.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #8660e9 !important;
}
.main-heading.border-b {
  border-bottom: solid 1px #ededed;
  padding-bottom: 15px;
  margin-bottom: 20px !important;
}
.custom-form .pretty .state label {
  padding-left: 6px;
}
.custom-form .pretty .state label:before {
  top: 1px;
}
.custom-form .pretty .state label:after {
  top: 1px;
}
.custom-form .form-control {
  font-size: 14px;
  height: 38px;
}
.custom-form .form-control:focus {
  box-shadow: none;
}
.custom-form textarea.form-control {
  height: auto;
}
.in-stock-box_red {
  background: #ff0000;
  font-size: 12px;
  text-align: center;
  border-radius: 25px;
  padding: 4px 15px;
  display: inline-block;
  color: #fff;
}
.in-stock-box_green {
  background: #2e8b2e;
  font-size: 12px;
  text-align: center;
  border-radius: 25px;
  padding: 4px 15px;
  display: inline-block;
  color: #fff;
}
