.__card__ {
  padding: 20px 25px;
  cursor: pointer;
}
.__card__ > .dots {
  width: 10px;
  height: 10px;
  color: gray;
}
.product__image__size_ {
  width: 100%;
  height: 180px;
  object-fit: contain;
}
.card__heart {
  float: right;
  color: lightgray;
}
.card__details > p {
  padding: 0;
  margin: 0;
}
.card__details > .title {
  font-weight: 600;
  font-size: 17px;
  color: rgb(165, 165, 165);
}
.card__details > p {
  font-size: 14px;
}
.card__details > .span1 {
  font-weight: bold;
}
.card__details > .span2 {
  color: gray;
  padding-left: 7px;
  text-decoration: line-through;
}
.card__details > .span3 {
  padding-left: 7px;
  color: rgb(2, 98, 2);
  font-size: 14px;
  font-weight: 600;
}
.productDet {
  position: relative;
  transition: 0.5s;
  height: 70px;
}
/* .productDet > .card__size {
  position: absolute;
  opacity: 0;
} */
.card__image:hover .productDet_scale {
  transition: 0.5s;
  transform: translateX(-5%);
}
/* .__card__:hover .productDet > .card__size {
  opacity: 1;
  transition: 1.75s;
} */
