.click-here {
  font-size: 18px;
  color: rgb(21, 21, 238);
}
.click-here:hover {
  text-decoration: none;
}
.fsp {
  font-size: 22px;
}
.cps {
  font-size: 25px;
  height: 50px;
}
.arb:hover {
  text-decoration: none;
}
.total-card {
  position: relative;
  top:-44px;
  right: 10px;
}
.cdet {
  top: -48px;
}

.img-sm {
  width: 80px;
  height: 80px;
}

.itemside .info {
  /* padding-left: 124px; */
  padding: 0 10px;
  /* padding-right: 20px; */
}

.table-shopping-cart .price-wrap {
  line-height: 1.2;
  margin-top: 0px;
}
.total-price {
  margin-top: -7px;
}

.table-shopping-cart .price {
  font-weight: bold;
  margin-right: 5px;
  display: block;
}

.text-muted {
  color: #969696 !important;
}

a {
  text-decoration: none !important;
}

.itemside {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  margin-left: 10px;
  align-items: center;
  height: 100%;
  width: 240px;
}

.quantity-cart {
  display: flex;
  align-items: center;
}

.dlist-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

[class*="dlist-"] {
  margin-bottom: 5px;
}

.price {
  font-weight: 600;
  color: #212529;
}
/* 
.btn.btn-out {
  outline: 1px solid #fff;
  outline-offset: -5px;
} */

.btn-main {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 13px;
  padding: 4px 19px;
  cursor: pointer;
  color: #fff;
  width: 100%;
}

.btn-light {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f8f9fa;
  font-size: 12px;
}

.btn-light:hover {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-apply {
  font-size: 11px;
}
.showitem {
  border: 1px solid whitesmoke;
  border-radius: 6px;
  box-shadow: 2px 2px 5px whitesmoke;
  width: 50px;
  margin: 0;
  /* height: 28px; */
  text-align: center;
  color: black;
  /* padding-top: 2px; */
}

label.radio1 span {
  padding: 5px 12px;
  display: inline-block;
  color: #68696B;
  border-radius: 3px;
  font-size: 12px !important;
  font-weight: bold;
  cursor: pointer;
  background-color: gainsboro;
  margin: 1px;
}

label.radio1 input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: pointer;
}


label.radio1 input:checked+span {
  border-color: #5dbad1;
  background:rgb(26, 173, 241);
  color: black
}

label.radio2 span {
  padding: 5px 8px;
  display: inline-block;
  color:#fafaff;
  width: 50px;
  border-radius: 3px;
  font-size: 10px !important;
  text-transform: capitalize;
  font-weight: bold;
  cursor: pointer;
  background-color:coral;
  margin: 1px;
}

label.radio2 input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: pointer;
}


label.radio2 input:checked+span {
  border-color: #5dbad1;
  background:#9ee2e9;
  color: black
}

#quantity {
  width: 45px;
  height: 21px;
  border: 1px solid sandybrown;
  margin: 1px 0 0 1px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
}

.btn-number {
  border: none;
  margin-left: 2px;
  border-radius: 5px;
  background: coral;
}
.buy-button {
  width: 83%;
  border-radius: 14px;
}

@media only screen and (max-width: 615px) {
  .total-card {
    position: relative;
    top: 0px;
    left: 0px;
  }
}
@media only screen and (min-width: 615px) and (max-width: 768px) {
  .total-card {
    position: relative;
    top: 0px;
    left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 998px) {
  .total-card {
    position: relative;
    top: 0px;
    left: 0px;
  }
}
