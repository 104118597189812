.order-prod-desc {
  margin-top: 50px;
}

.desc-item {
  padding: 3px 0;
  display: flex;
}

.desc-item div:first-child {
  width: 150px;
}
