.active_nav01 {
  color: #e9ecef !important;
  /* border-left-color: #3bdd4e !important; */
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.0875) 50%,
    transparent
  ) !important;
  border-left-color: #3b7ddd !important;
}

.active_nav1 {
  font-weight: 400 !important;
  border-left: 0 !important;
  color: #518be1 !important;
  background: transparent !important;
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.0875) 50%,
    transparent
  ) !important;
}

.sidebar {
  transition: all 0.7s ease-out;
}
