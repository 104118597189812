.products__description {
  transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  perspective: 1000;
  backface-visibility: hidden;
}
.product__container {
  padding-top: 25px;
  padding-bottom: 25px;
}
.product__image_all {
  max-width: 100%;
}
.product__horizontal_line {
  border-color: #e5e5e5;
  /* margin: 15px 0; */
}
.product__secondary__text {
  color: #b6b6b6;
}
.list__inline {
  margin: 0;
}
.list__inline li {
  padding: 0;
}
.product__cards__wrapper {
  position: relative;
  width: 100%;
  height: 390px;
  border: 1px solid #e5e5e5;
  border-bottom-width: 2px;
  overflow: hidden;
  margin-bottom: 30px;
}
.product__cards__wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.product__cards__wrapper:hover:after {
  opacity: 1;
}
.product__cards__wrapper:hover .product__images__holder:before {
  opacity: 0.75;
}
.product__cards__wrapper:hover .product__images__holder:after {
  opacity: 1;
  transform: translate(-50%, -50%);
}
.product__cards__wrapper:hover .product__images__holder--original {
  transform: translateY(-15px);
}
.product__cards__wrapper:hover .products__description {
  height: 205px;
}
@media (min-width: 768px) {
  .product__cards__wrapper:hover .products__description {
    height: 185px;
  }
}
.product__images__holder {
  display: block;
  position: relative;
  width: 100%;
  height: 310px;
  background-color: #fff;
  z-index: 1;
}
@media (min-width: 768px) {
  .product__images__holder {
    height: 325px;
  }
}
.product__images__holder:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #4caf50; */
  background-color: #9198cf;
  opacity: 0;
  z-index: 5;
  transition: opacity 0.6s;
}
.product__images__holder:after {
  content: "+";
  /* content: "🛒"; */
  font-family: "Raleway", sans-serif;
  font-size: 70px;
  color: #4caf50;
  text-align: center;
  position: absolute;
  top: 92.5px;
  left: 50%;
  width: 75px;
  height: 75px;
  line-height: 75px;
  background-color: #fff;
  opacity: 0;
  border-radius: 50%;
  z-index: 10;
  transform: translate(-50%, 100%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-out;
}
@media (min-width: 768px) {
  .product__images__holder:after {
    top: 107.5px;
  }
}
.product__images__holder .product__images__holder__link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
}
.product__images__holder .product__images__holder--original {
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.image-liquid {
  width: 100%;
  height: 325px;
  background-size: cover;
  background-position: center center;
}
.products__description {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 10px 15px;
  overflow: hidden;
  background-color: #fafafa;
  border-top: 1px solid #e5e5e5;
  transition: height 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 2;
}
@media (min-width: 768px) {
  .products__description {
    height: 65px;
  }
}
.products__description p {
  margin: 0 0 5px;
}
.products__description .products__description__title {
  font-family: "Raleway", sans-serif;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  font-size: 18px;
  line-height: 1.25;
}
.products__description .products__description__title:after {
  content: "";
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(250, 250, 250, 1)
  );
}
.products__description .products__description__title a {
  text-decoration: none;
  color: inherit;
}
.products__description .products__description__category {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  text-align: justify;
}
.products__description .products__description__price {
  color: #4caf50;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0.06em;
}
@media (min-width: 768px) {
  .products__description .products__description__price {
    text-align: right;
  }
}
.products__description .product__cards__wrapper {
  margin-bottom: 15px;
}
.products__description .colors__list {
  font-size: 0;
}
.products__description .colors__list__item {
  width: 25px;
  height: 10px;
  position: relative;
  z-index: 1;
  transition: all 0.2s;
}
.products__description .colors__list__item:hover {
  width: 40px;
}
.products__description .colors__list__item--red {
  background-color: #f44336;
}
.products__description .colors__list__item--blue {
  background-color: #448aff;
}
.products__description .colors__list__item--green {
  background-color: #cddc39;
}
.products__description .colors__list__item--orange {
  background-color: #ff9800;
}
.products__description .colors__list__item--purple {
  background-color: #673ab7;
}

/* @media screen and (max-width: 700px) {
  .product__price__off {
    display: none;
  }
} */
