
.fb-profile{
    height:40vh;
    width: 100%;
}
.fb-profile img.fb-image-lg{
    z-index: 0;
    width: 100% !important;  
    margin-bottom: 10px;
    height: 40vh;
}

.fb-image-profile
{
    margin: -80px 10px 0px 50px;
    z-index: 9;
    width: 25%; 
    box-shadow: 0px 0px 20px black;
    border-radius: 10px;
    cursor: pointer;
}
.rounded-md-circle{
    border:none;
    border-radius:100%;
}

@media (max-width:768px)
{
    
.fb-profile-text>h1{
    font-weight: 700;
    font-size:16px;
}

.fb-image-profile
{
    margin: -45px 10px 0px 25px;
    z-index: 9;
    width: 20%; 
}
}