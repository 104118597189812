.quickview-button {
  width: 100%;
  /* margin-left: -19px; */
  background-color: #34d399;
  color: white;
  border: white;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}
.quickview-button:hover {
  background-color: #20c997;
  color: white;
}
.product_view .modal-dialog {
  max-width: 800px;
  width: 100%;
}
.pre-cost {
  text-decoration: line-through;
  color: #a5a5a5;
}
.space-ten {
  padding: 10px 0;
}
/* .product_img img {
  width: 200px;
  height: 400px;
}
.pro {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
} */
#row .hel .qtyform {
  margin-top: 0px;
  width: 100%;
  margin-left: 0px;
}
.qbtn {
  background-color: #34d399;
  border: none;
}
.qbtn:hover {
  background-color: #15e2a5;
}
.react-responsive-modal-modal {
  width: 800px;
}

@media only screen and (max-width: 310px) {
  .pro {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 615px) {
  .react-responsive-modal-modal {
    width: 466px;
    top: 30px;
  }
  .pro {
    flex-direction: column;
  }
}
@media only screen and (min-width: 615px) and (max-width: 768px) {
  .react-responsive-modal-modal {
    width: 340px;
    top: 30px;
  }
  .pro {
    flex-direction: column;
    gap: 20px;
  }
}

.css-1f6mae5,
.css-14i60ud {
  padding-bottom: 6px;
  color: black;
  background-color: #3bdd7494;
}
.css-1im4us5 {
  background: aliceblue;
}
