.card-header:first-child {
  border-radius: 0;
}
.active9 {
  background-color: #1f3779;
  color: white;
  cursor: pointer;
}
/* .user-card{
    display:flex;
    flex-direction:row;
} */
.list-group-item-action:focus .collasp1:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: rgb(57, 245, 211);
}
.list-group-item-action {
  color: black;
  font-weight: bold;
}

.list-group-item-action:hover {
  background-color: rgb(62, 85, 179);
  color: #eb3007;
}
#collapseThree,
#collapseTwo {
  position: relative;
  bottom: 160px;
  /* left: 290px; */
}

.order-prod-desc {
  margin-top: 50px;
}

.desc-item {
  padding: 3px 0;
  display: flex;
}

.desc-item div:first-child {
  width: 150px;
  font-weight: bold;
}

.card-container {
  padding: 100px 0px;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.profile-card-4 {
  max-width: 620px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: -15px auto;
  cursor: pointer;
}

.profile-card-4 img {
  transition: all 0.25s linear;
}

.profile-card-4 .profile-content {
  position: relative;
  padding: 25px;
  background-color: #fff;
}

.profile-card-4 .profile-name p {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1.5px;
}

.profile-card-4 .profile-description {
  color: #777;
  font-size: 12px;
  padding: 10px;
}

.profile-card-4 .profile-description h5 {
  /* background-color: #eeeeee4a; */
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.profile-card-4 .profile-content::before {
  content: "";
  position: absolute;
  height: 20px;
  top: -10px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 0;
  transform: skewY(3deg);
}

.profile-card-4:hover img {
  transform: rotate(5deg) scale(1.1, 1.1);
  filter: brightness(110%);
}
@media only screen and (max-width: 600px) {
  .profile-card-4 .profile-description {
    display: block !important;
  }
  .profile-card-4 .profile-description h5 {
    margin-left: 0px !important;
  }
}

.user-sidebar {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
}

.user-sidebar-content {
  margin-bottom: 10px;
}

.user-sidebar-title {
  font-weight: bold;
  padding: 4px 0 4px 15px;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  /* width: 132px; */
}

.user-sidebar {
  width: 200px;
}

.user-sidebar-title:hover {
  background-color: #e8e8e8;
  border-radius: 10px;
}

.isTitleSelected {
  background-color: #e8e8e8;
  border-radius: 10px;
}

.user-sidebar-subtitle:hover {
  text-decoration: underline;
  font-weight: bold;
}

.user-subtitle-container {
  margin: 7px 0 0 45px;
}

.user-sidebar-subtitle {
  cursor: pointer;
}

/*  */

.user-profile__image {
  height: 80px !important;
  width: 80px !important;
  /* width: 100% !important; */
  object-fit: contain !important;
  border-radius: 50%;
}
