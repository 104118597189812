.drawer_primary_container_:hover {
  /* background: rgb(226 230 235); */
  background: #e2e6eb;
}

@keyframes moveSideBar {
  0% {
    transform: translateX(-420px);
    z-index: 100;
  }
  100% {
    transform: translateX(-0px);
  }
}

.drawer_primary_container_items {
  background: #e2e6eb !important;
  font-weight: bold;
}
