.rs-drawer-wrapper {
  z-index: 99999 !important;
}

.rs-drawer-backdrop {
  z-index: 99999 !important;
}

.dl {
  border-right: none;
}
.ml-5 {
  text-align: justify;
}
#saler .active-navigation {
  color: #fca401;
}

#home-nav #navbarrw li {
  font-size: 18px;
  padding: 10px 2px;
}
#navbarSupportedContent ul li {
  color: black;
}

@media (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}

.saler-nav {
  background-color: #5a60e1;
}

.hoverdrops {
  display: none;
  position: absolute;
  margin-top: 2px;
  left: 0px;
  right: 50;
  padding: 27px 14px 0;
  z-index: 4;
  width: 670px;
}

.hoverdrops a.drops {
  font-size: 14px;
  white-space: normal;
}
.hoverdrops a.drops:hover {
  color: #ff5500;
  text-decoration: none;
  background-color: white;
}

#link-1.navhover:hover {
  border-bottom: 2px solid #37587b;
}

#link-2.navhover:hover {
  border-bottom: 2px solid #37587b;
}
/*end of mega menu*/

#home-nav {
  height: 75px !important;
  background-color: #319ffb !important;
  border-top: 0.5px solid white;
}

#home-nav .nav-item {
  margin-right: 20px;
}

.rw-nav-item {
  cursor: pointer;
}

._saler {
  font-weight: 600;
  font-size: 18px;
  /* background: linear-gradient(to right, black, black 50%, #fff 50%); */
  background-clip: text;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  color: white;
  /* background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease; */
}

._saler:hover {
  /* background-position: 0 100%; */
}

.nav-item:hover {
  /* background-position: 0 100%; */
}

.wrappereds {
  position: relative;
}

li .wrappereds {
  position: absolute;
  z-index: 10;
  visibility: hidden;
}
li:hover > .wrappereds {
  visibility: visible;
}

.nav-cat-sub {
  color: #035aa1;
  padding: 0;
  margin: 0;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  transition: all 0.3s ease;
  z-index: 2;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 20rem;
  height: 65vh;
}

.nav-cat_list {
  padding: 0 2px 0 0 !important;
  max-height: 100px;
  /* position: relative; */
  transition: all 0.3s ease;
  width: 20rem;
}

.nav-cat-sub .nav-cat-subitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 15px;
  border-bottom: 1px dotted #319ffb;
  margin-left: 5px;
}

.nav-cat-subitem:hover > #_cat,
.nav-cat-subitem:hover {
  background-color: #319ffb;
  color: white;
  /* -webkit-text-fill-color: white; */
}

#_cat {
  font-size: 16px;
  font-weight: 600;
  color: #035aa1;
  /* -webkit-text-fill-color: #035aa1; */
}

.__titlee {
  font-size: 18px;
  color: white !important;
}

.__titlee:hover {
  color: black !important;
}

.nav-cat-third-item {
  margin: 0px 0px 0px -15rem;
  padding: 0;
  border-radius: 5px;
  width: 20rem;
  height: 65vh;
}

.nav-cat-sec-item {
  margin: 0px 0px 0px -15rem;
  padding: 0;
  border-radius: 5px;
  width: 20rem;
  height: 65vh;
}
@media screen and (max-width: 850px) {
  .wraps {
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;
    height: auto;
    padding-bottom: 4rem;
  }
  .nav-cat-sub .nav-cat-subitem {
    gap: 2px;
    padding: 6px;
  }
  #_cat {
    font-size: 14px;
    color: #035aa1;
    /* -webkit-text-fill-color: #035aa1; */
  }
}
@media screen and (max-width: 500px) {
  .wraps {
    overflow-x: scroll;
    overflow-y: scroll;
    width: 100%;
    height: 50vh;
    padding-bottom: 4rem;
  }
  .nav-cat-sub .nav-cat-subitem {
    gap: 2px;
    padding: 4px;
  }
  .nav-cat-sub {
    width: 10rem;
  }
  #_cat {
    font-size: 12px;
    color: #035aa1;
    /* -webkit-text-fill-color: #035aa1; */
  }
  .nav-cat-sec-item {
    margin: 0px 0px 0px -25rem;
    padding: 0;
    width: 10rem;
  }
  .nav-cat-third-item {
    margin: 0px 0px 0px -25rem;
    width: 10rem;
  }
}
/* @media (max-width: 575.98px) {
  .megamenu {
    padding: 0;
  }
  .hoverdrops {
    width: 255px;
    text-align: center;
  }
  .main-nav .onelink a {
    font-family: sans-serif;
    font-size: 15px;
    margin-left: -31px;
    width: 167px;
    text-align: justify;
  }
  .btnhover {
    color: white;
    font-family: sans-serif;
    font-size: 15px;
    margin-left: -16px;
    width: 167px;
    text-align: justify;
  }
  .main-nav .onelink a:hover {
    color: orangered;
    text-decoration: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .megamenu {
    padding: 0;
  }
  .hoverdrops {
    width: 255px;
    text-align: center;
  }
  .main-nav .onelink a {
    font-family: sans-serif;
    font-size: 15px;
    margin-left: -16px;
    width: 167px;
    text-align: justify;
  }
  .btnhover {
    color: white;
    font-family: sans-serif;
    font-size: 15px;
    margin-left: -21px;
    width: 167px;
    text-align: justify;
  }
  .main-nav .onelink a:hover {
    color: orangered;
    text-decoration: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .megamenu {
    padding: 0;
  }
}

@media only screen and (min-width: 998px) and (max-width: 1179px) {
  ul .nav-item,
  .main-nav .onelink a {
    font-size: 11px;
    margin-left: -10px;
  }
  .menu-icon {
    margin-right: 17px;
  }
} */
