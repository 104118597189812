.box__80 {
  width: 85%;
}

.box__20 {
  width: 15%;
}
.text__job__right {
  text-align: right;
}

.job__head h2,
.job__title__detail {
  font-weight: bold;
  color: rgb(35 64 122);
  margin: 0;
}

.job__content {
  margin-top: 15px;
}

.job__desc {
  margin-top: 10px;
}

.job__box__col {
  display: inline-block;
  vertical-align: text-top;
}

.job__function {
  display: block;
}
.job__box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.job__box {
  background: #f2f2f2;
  border-radius: 4px;
  padding: 1em 1em;
  border-radius: 10px;
  margin: 2em 0 !important;
  text-align: left;
  border: 1px solid lightgray;
}
.job__box:hover {
  box-shadow: 2px 2px 6px grey;
  cursor: pointer;
  transition: linear 0.2s;
}

.job__buttons {
  outline: none;
  border: none;
  font-size: 1em !important;
  padding: 5px 20px !important;
  text-decoration: none;
  -webkit-box-shadow: #e28b00 0 1px 0;
  -moz-box-shadow: #e28b00 0 1px 0;
  box-shadow: #e28b00 0 1px 0;
  background-color: #f6ae2b;
  background-image: -webkit-linear-gradient(
    linear,
    50% 0,
    50% 100%,
    color-stop(0%, #f6ae2b),
    color-stop(70%, #f39d13)
  );
  background-image: -webkit-linear-gradient(#f6ae2b, #f39d13 70%);
  background-image: -moz-linear-gradient(#f6ae2b, #f39d13 70%);
  background-image: -o-linear-gradient(#f6ae2b, #f39d13 70%);
  background-image: linear-grad;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  color: white;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1em;
}

.form__jobs {
  padding: 10px;
  max-width: 555px;
}

.job__form__card {
  background-color: whitesmoke;
  border: 0.5px solid rgb(231, 231, 231);
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.job__detail__container {
  padding: 15px 10px 10px;
}

.job__desc__detail {
  margin-top: 20px;
}

.job__desc__detail h5 {
  margin: 0;
  font-weight: bold;
  color: rgb(63, 63, 63);
}

/* --------------------------------- vacanc --------------------------------- */
.vacancy__img_ {
  width: 100%;
  height: 400px;
  object-fit: fill;
}
.quotation__image {
  width: 100%;
  height: 320px;
  object-fit: fill;
}

@media screen and (max-width: 768px) {
  .vacancy__img_ {
    height: 200px;
  }
  .quotation__image {
    height: 200px;
  }
}
