.user-order-report {
  display: flex;
  padding: 2px;
  color:black;
  font-weight:bold;
}

.user-order-report div:first-child {
  width: 110px;
}

.user-order-report-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.order-card{
  margin-top:-5rem;
  padding:2rem;
  max-width:620px;
}

@media only screen and (max-width:1178px){
  .order-card{
    position: relative;
    top:5rem;
  }
}
