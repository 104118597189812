.dropdown {
  position: relative;
  display: inline-block;
  /* border-right: 1px solid black; */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #888484;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 7px 7px;
  text-decoration: none;
  /* display: block; */
  font-size: 10px;
  border: 2px solid white;
  width: auto;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.down-arrow {
  height: 10px;
  margin-left: 5px;
}

.dropbtn {
  font-size: 10px;
  padding: 6px 6px 6px 0px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  border: none;
  background: none;
}

.flag-image {
  width: 10px;
  height: 10px;
  margin-top: 8px;
  margin-left: 3px;
}
