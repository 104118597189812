
.all-login{
  flex-direction:column;
}

.error {
  color: #dc3545;
  /* float: left; */
}
.logo-image{
  box-shadow: 0 -5px 3px -3px rgb(21, 13, 13), 0 5px 3px -3px rgb(22, 16, 16);
  border-radius: 50%;
  width: 140px;
}
.logo-image:hover{
  box-shadow: 0px 1px 10px rgb(104, 255, 197);
}



.cbtn {
  background-color: #34d399;
  width:100%;
  color: #fff;
  border-radius:10px;
  /* padding:2px; */
  font-size: 18px;
}

.cbtn:hover {
  background-color: #7debc3;
  cursor: pointer;
}
.uform{
  flex-direction: column;
    align-items: center;
}
.uform .input_box {
  height:40px;
  width: 100%;
  position: relative;
  margin-top: 15px;
}
.input_box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: 1px solid rgb(43, 92, 99);
  border-radius: 5px;
  padding-left: 45px;
  font-size: 15px;
  transition: all 0.3s ease;
}
.input_box input:focus {
  border-color: #34d399;
}
.input_box .icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: black;
}
