.active_nav010 {
  color: #e9ecef !important;
  /* border-left-color: #3bdd4e !important; */
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.0875) 50%,
    transparent
  ) !important;
  border-left-color: #e2c93e !important;
}

.active_nav100 {
  font-weight: 400 !important;
  border-left: 0 !important;
  color: #e1bf51 !important;
  background: transparent !important;
  background: linear-gradient(
    90deg,
    rgba(59, 125, 221, 0.1),
    rgba(59, 125, 221, 0.0875) 50%,
    transparent
  ) !important;
}

.sidebar {
  transition: all 0.7s ease-out;
}
