#ul-list {
  list-style: none;
  /* border: 1px solid #e4e4e7; */
}

.imgg {
  width: 103.4%;
}
.b-video{
  width: 104.2%;
}

.dropdown-submenu {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.dropdown-submenu:hover {
  color: rgb(72 93 137);
  font-weight: bold;
}
.dropdown-submenu > a:after {
  float: right;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
@media only screen and (max-width:768px) {
  #ul-list{
    margin-left:0px !important;
    padding-left: 0px;
    border: none;
  }
  #sm-imageslide{
    width:75%;
  }
  .imgg,.b-video{
    margin-left:-5px;
  }
}

