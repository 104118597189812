.cin {
  height: 538px;
  width: 103%;
}

.slid-im {
  width: 103%;
  height: 538px;
}
#demo {
  right: 12px;
}
.carousel-caption {
  margin-bottom: 100px;
}
.carousel-caption h1 {
  color: #f3a619;
  font-weight: bolder;
  font-size: 50px;
}
.carousel-caption h2 {
  /* background-color: cornsilk;  */
  box-shadow: 0px 0px 10px white;
  width: 40%;
  margin-left: 18rem;
  padding: 5px;
  color: #04ff00;
  border: 1px solid wheat;
}

@media only screen and (max-width: 768px) {
  .cin {
    height: 324px;
    width: 104% !important;
  }
  .slid-im {
    width: 104% !important;
  }
}
@media only screen and (max-width: 520px) {
  .cin {
    height: 324px;
    width: 106% !important;
  }
  .slid-im {
    width: 106% !important;
  }
  @media only screen and (min-width: 200px) and (max-width: 400px) {
    .cin {
      width: 116% !important;
    }
    .slid-im {
      width: 116% !important;
    }
  }
}

@media only screen and (min-width: 150px) and (max-width: 500px) {
  .slider__images__ {
    height: 250px !important;
  }
}
/* -------------------------------------------------------------------------- */
/*                                   animate                                  */
/* -------------------------------------------------------------------------- */
