.contentt {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay_option {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  margin-top: 30px;
}

.pay_item {
  cursor: pointer;
  padding: 15px 0;
  width: 150px;
  border-radius: 10px;
  flex: 0 1 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 5px rgb(225, 225, 225);
}

.pay_item:hover {
  transform: scale(1.1);
  filter: brightness(100%);
  transition: 0.3s all ease-in-out;
}

.pay_item label {
  margin-top: 10px;
  cursor: pointer;
}

.pay_item p {
  font-size: 20px;
}

.contentt .active {
  border: 2px solid grey;
  box-shadow: 5px 5px 5px rgb(225, 225, 225);
}

.contentt .inactive {
  background-color: rgb(255, 255, 255);
  filter: brightness(98%);
}

.pay_btn {
  margin-top: 40px;
}

.pay_btn button,
.ebtn {
  border: none;
  padding: 7px 50px;
  font-size: 16px;
  font-weight: bold;
}
