.nav-listed .active-navigation {
  /* box-shadow: 0px 0px 1px white; */
  /* background-color: rgb(118, 118, 226) !important; */
  color: white !important;
  width: 6rem;
  text-align: center;
  border-radius: 5px;
}
ul {
  list-style: none;
}

.menu-iconsed {
  color: #eee;
  position: absolute;
  font-size: 2.5rem;
  top: 47%;
  right: 2rem;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1500;
  display: none;
}
.head-nav {
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgb(35 64 122);
  /* background-image: linear-gradient(-8deg, #0b8793, #360033); */

  position: sticky;
  top: 0px;
  z-index: 999;
}

.nav-listed {
  display: flex;
}
.nav-listed li {
  line-height: 4rem;
  /* position: relative; */
}

.sub-menued li {
  line-height: 2.5rem;
}
.nav-listed a {
  display: block;
  color: #eee !important;
  padding: 0 0rem;
  font-size: 1.08rem;
  text-transform: capitalize;
  transition: color 650ms;
  font-weight: 500;
}
.nav-listed a:hover {
  color: #eb3007 !important;
}

.wrappered {
  position: relative;
  height: 70vh;
  padding: 0%;
  margin: 0%;
}

li .wrappered {
  position: absolute;
  z-index: 10;
  visibility: hidden;
}
li:hover > .wrappered {
  visibility: visible;
}

.sub-menued {
  width: 20rem;
  border-top: 3px solid #eb3007;
  background-color: #1f3779;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
}

.ss-menu {
  margin-left: -16.2rem;
  width: 20rem;
  /* height: auto; */
  height: 70vh;
  margin-top: 0px;
  border-left: 3px solid #eb3007;
  border-top: 3px solid #eb3007;
  transition: 0.1s;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sss-menu {
  margin-left: -16.7rem;
  /* height: auto; */
  height: 70vh;
  border-top: 3px solid #eb3007;
  border-left: 3px solid #eb3007;
  transition: 1s;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ss-menu li {
  margin-left: -2rem;
  width: 112%;
}
.parent,
.ten-parent {
  margin-top: 2px;
  margin-left: -2rem;
  width: 111%;
  padding-left: 2rem;
  margin-bottom: 0.16rem;
  cursor: pointer;
}
.parent:hover,
.ten-parent:hover {
  color: #eb3007 !important;
  background-color: rgb(164, 185, 208);
}

@media screen and (max-width: 830px) {
  .text h1 {
    font-size: 4rem;
  }

  .text .btn {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 650px) and (max-width: 850px) {
  .nav-listed {
    position: absolute;
    top: 64px;
    left: 0;
    height: 100vh;
    flex-direction: column;
    background-color: #1f3779;
    z-index: 1000;
    align-items: initial;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
  .nav-listed > li {
    line-height: 6rem;
  }
  .wrappered {
    position: initial;
    border: 3px solid transparent;
    /* border-left-color: #eb3007;
    border-bottom-color: #eb3007; */
    height: auto !important;
    margin-left: 1rem;
    margin-top: -2rem;
    max-height: 0;
    width: 12rem;
  }
  .sub-menued {
    width: 16rem;
    margin-left: -4rem;
  }
  .wrappered::before {
    display: none;
  }
  .nav-listed li:hover > .wrappered {
    opacity: 1;
    visibility: visible;
    max-height: initial;
  }
  .parent a {
    font-size: 16px;
    line-height: 1.4rem;
    /* margin-left: -5rem; */
  }
  .ss-menu {
    margin-left: -10.2rem;
    margin-top: 1.8rem;
    border: none;
    width: 11rem;
    border-left: 3px solid #eb3007;
    border-top: 3px solid #eb3007;
  }
  .sss-menu {
    margin-left: -8.2rem;
    margin-top: 3.6rem;
    border: none;
    width: 11rem;
    border-left: 3px solid #eb3007;
    border-top: 3px solid #eb3007;
  }
  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }
  .menu-iconsed {
    display: block;
  }
  .ion-md-close {
    display: none;
  }
  .head-nav.active .ion-md-close {
    display: block;
  }
  .head-nav.active .ion-md-menu {
    display: none;
  }
  .head-nav.active .nav-listed {
    display: flex;
  }
}
@media screen and (min-width: 500px) and (max-width: 650px) {
  .nav-listed {
    position: absolute;
    top: 64px;
    left: 0;
    height: 100vh;
    flex-direction: column;
    background-color: #1f3779;
    z-index: 1000;
    align-items: initial;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
  .nav-listed > li {
    line-height: 6rem;
  }
  .wrappered {
    position: initial;
    border: 3px solid transparent;
    /* border-left-color: #eb3007;
    border-bottom-color: #eb3007; */
    height: auto !important;
    margin-left: 1rem;
    margin-top: -2rem;
    max-height: 0;
    width: 12rem;
  }
  .sub-menued {
    width: 13rem;
    margin-left: -4rem;
  }
  .wrappered::before {
    display: none;
  }
  .nav-listed li:hover > .wrappered {
    opacity: 1;
    visibility: visible;
    max-height: initial;
  }
  .parent a {
    font-size: 14px;
    line-height: 1.4rem;
    /* margin-left: -5rem; */
  }
  .ss-menu {
    margin-left: -10.2rem;
    margin-top: 1.8rem;
    border: none;
    width: 11rem;
    border-left: 3px solid #eb3007;
    border-top: 3px solid #eb3007;
  }
  .sss-menu {
    margin-left: -10.2rem;
    margin-top: 3.6rem;
    border: none;
    width: 10rem;
    border-left: 3px solid #eb3007;
    border-top: 3px solid #eb3007;
  }
  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }
  .menu-iconsed {
    display: block;
  }
  .ion-md-close {
    display: none;
  }
  .head-nav.active .ion-md-close {
    display: block;
  }
  .head-nav.active .ion-md-menu {
    display: none;
  }
  .head-nav.active .nav-listed {
    display: flex;
  }
}
@media screen and (min-width: 430px) and (max-width: 500px) {
  .nav-listed {
    position: absolute;
    top: 64px;
    left: 0;
    height: 100vh;
    flex-direction: column;
    background-color: #1f3779;
    z-index: 1000;
    align-items: initial;
    display: none;
    overflow-y: auto;
    overflow-x: scroll;
    width: 100%;
  }
  .nav-listed > li {
    line-height: 6rem;
  }
  .wrappered {
    width: 10rem;
  }
  .sub-menued {
    width: 10rem;
    margin-left: 0px;
  }
  .wrappered::before {
    display: none;
  }
  .nav-listed li:hover > .wrappered {
    opacity: 1;
    visibility: visible;
    max-height: initial;
  }
  .parent a {
    font-size: 14px;
    line-height: 1.4rem;
    text-align: left;
    /* margin-left: -5rem; */
  }
  .ss-menu {
    width: 8rem;
    margin-left: -8.5rem;
    border-left: 3px solid #eb3007;
    border-top: 3px solid #eb3007;
  }
  .sss-menu {
    width: 8rem;
    margin-left: -6.5rem;
    border-left: 3px solid #eb3007;
    border-top: 3px solid #eb3007;
  }
  .sss-menu li a {
    margin-left: -20px;
    text-align: left;
  }

  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }
  .menu-iconsed {
    display: block;
  }
  .ion-md-close {
    display: none;
  }
  .head-nav.active .ion-md-close {
    display: block;
  }
  .head-nav.active .ion-md-menu {
    display: none;
  }
  .head-nav.active .nav-listed {
    display: flex;
  }
}
@media screen and (max-width: 430px) {
  .nav-listed {
    position: absolute;
    top: 64px;
    left: 0;
    height: 100vh;
    flex-direction: column;
    background-color: #1f3779;
    z-index: 1000;
    align-items: initial;
    display: none;
    overflow-y: auto;
    overflow-x: scroll;
    width: 100%;
  }
  .nav-listed > li {
    line-height: 6rem;
  }
  .parent a {
    font-size: 14px;
    line-height: 1.4rem;
    text-align: left;
    /* margin-left: -5rem; */
  }
  .sub-menued {
    width: 10rem;
    margin-left: 0rem;
  }
  .ss-menu {
    width: 8rem;
    margin-left: -6.5rem;
  }
  .sss-menu {
    width: 8rem;
    margin-left: -6.5rem;
  }

  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }
  .menu-iconsed {
    display: block;
  }
  .ion-md-close {
    display: none;
  }
  .head-nav.active .ion-md-close {
    display: block;
  }
  .head-nav.active .ion-md-menu {
    display: none;
  }
  .head-nav.active .nav-listed {
    display: flex;
  }
}

/* -------------------------------------------------------------------------- */
/*                                    csss                                    */
/* -------------------------------------------------------------------------- */

.nav__hover_color:hover {
  color: #eb3007;
  font-weight: bold;
}
.nav__hover_color {
  /* color: white; */
  font-weight: 550;
}
.active__navigation_ {
  color: #eb3007;
  font-weight: bold;
}
