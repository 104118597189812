.header {
  text-align: left;
  /* margin-left: 20px; */
  border: 1px solid rgb(180, 176, 176);
  background-color: #fff;
}

.login {
  float: right;
  margin-top: 0px;
  font-size: 11px;
  color: rgb(7, 6, 6);
  font-family: sans-serif;

  margin-right: 10px;
  margin-top: 5px;
}

.store {
      float: right;
    font-size: 12px;
    color: rgb(22 177 251);
    font-family: sans-serif;
    margin-right: 15px;
    margin-top: 31px;
}

.login:hover {
  color: orange;
  text-decoration: none;
}

.store:hover {
  color: orange;
}
.store a:hover {
  color: orange;
}
.hr {
  margin: 0.3rem 0;
}

.nav-resp {
  background-color: #ff5500;
  width: 100%;
  height: 50px;
}

.menu-icon-mobile {
  float: left;
  color: white;
  margin-top: 5px;
  height: 24px;
  width: 41px;
  margin-left: 5px;
}

.mobile-logo-image {
  width: 129px;
  height: 32px;
  margin-top: 10px;
}

.mobile-shop-icon {
  float: right;
  width: 23px;
  height: 50px;
  color: white;
  margin-right: 20px;
}

