.order-prod-desc {
  margin-top: 10px;
}

.select-list select {
  color: rgb(86, 86, 86) !important;
  background-color: transparent !important;
}

.desc-item {
  padding: 3px 0;
  display: flex;
}

.desc-item div:first-child {
  width: 150px;
}

.payment-option {
  margin: 10px 20px;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.payment-option:hover {
  /* transform: scale(1.2); */
}

.billing-button,
.billing-button:hover,
.billing-button:focus,
.billing-button::selection {
  outline: none;
  border: none;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .payment__modal {
    margin-top: 10px;
  }
}
