/* .about-usf {
  font-family: sans-serif;
} */
.about-us-bg {
  /* background-color: aquamarine; */
  background-image: linear-gradient(
    35deg,
    rgb(198, 226, 235) 45%,
    rgb(166, 202, 214) 45%
  );
}
.yumt {
  font-size: 40px;
}

.read-more {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.read-more h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.knlogo {
  box-shadow: 0px 5px 15px rgb(184, 183, 181);
  width: 400px;
  border-radius: 50%;
  margin-left: 14rem;
  margin-top: 2rem;
}
.company-story {
  background-color: rgb(127, 204, 240);
  box-shadow: 0px 0px 4px black;
}
.aboutpara {
  position: relative;
  width: 102.2%;
}
.abu-para {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 18px;
}
.abu-para:hover {
  color: #02c5ff;
}
.about-img {
  width: 102%;
  height: 100vh;
  margin-left: -12px;
  /* margin-top: 1px; */
}
.best-story {
  font-size: 45px;
  font-family: Publico Headline Black;
  text-transform: capitalize;
}
.f-hr {
  color: #1a0101;
  width: 80%;
}
.s-hr {
  color: black;
  width: 80%;
}
.mission,
.vision,
.objective {
  /* background-color: rgb(127, 204, 240); */
  /* background:linear-gradient(-25deg,rgb(54, 96, 172), rgb(127, 204, 240)); */
  background-image: linear-gradient(25deg, #eceddc 30%, rgb(127, 204, 240) 25%);
  margin-top: 7rem;
  margin-left: 1rem;
  box-shadow: 0px 0px 10px black;
  border-radius: 1rem;
  cursor: pointer;
}
.mission:hover,
.vision:hover,
.objective:hover {
  transform: translateY(-20px);
  background-image: linear-gradient(
    -25deg,
    #eceddc 30%,
    rgb(127, 204, 240) 25%
  );
}
.mission:hover h2,
.vision:hover h2,
.objective:hover h2 {
  font-size: 30px;
}
.mission h2,
.vision h2,
.objective h2 {
  text-transform: capitalize;
  text-align: center;
  padding-top: 3.5rem;
  font-weight: bold;
}
.mission p,
.vision p,
.objective p {
  font-size: 20px;
  color: black;
  text-align: justify;
  line-height: 1.9rem;
  padding: 2rem 1rem;
}

.s-section p {
  font-size: 20px;
}
.k-link {
  text-decoration: underline;
  color: rgb(17, 212, 238);
}
.k-link:hover {
  color: cyan;
  text-decoration: underline;
}

.ab-para {
  text-align: justify;
  color: black;
  /* background-color: #c9d8ef; */
  padding: 12px;
  box-shadow: 2px 2px 3px black;
}
.prologue {
  padding: 12px;
  box-shadow: -2px -2px 3px black;
}
.prologue p {
  text-align: justify;
  color: black;
  font-size: 20px;
}

.quote {
  text-align: left;
  font-size: 25px;
  margin-top: 150px;
  color: black;
}
.create-history h1,
.concentrate h1,
.month-part h1,
.ab-heading,
.introducation-heading {
  font-size: 40px;
  text-align: center;
}

.f-para {
  font-size: 23px;
  font-style: italic;
  color: black;
  text-align: left;
}
.quote-rounded,
.focus-para {
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
  background-color: rgb(127, 204, 240);
  height: auto !important;
  cursor: pointer;
}
.quote-rounded:hover,
.focus-para:hover {
  transform: translateY(-2rem);
}
.introducation-pa {
  background-color: #c9d8ef;
  transform: skewX(-12deg);
  padding: 12px;
  box-shadow: 0px 2px 3px black;
}
.introducation-para {
  font-size: 24px;
  font-weight: 500;
  color: #1a0101;
  text-align: justify;
  transform: skewX(0deg);
}
.introducation-para span {
  line-height: 35px;
  font-size: 55px;
  font-family: Montserrat;
  color: black;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
}
.second-introducation {
  background-color: #c9d8ef;
  transform: skewX(12deg);
  padding: 12px;
  box-shadow: 0px 2px 3px black;
  font-size: 20px;
  color: black;
  text-align: justify;
  line-height: 1.9rem;
}
.c-para {
  font-size: 20px;
  color: black;
  text-align: justify;
  box-shadow: 0px -2px 3px;
}
.cb-para h4 {
  font-size: 25px;
  color: black !important;
  text-transform: capitalize;
  text-align: center;
}
.cb-para p {
  font-size: 20px;
  color: black;
  text-align: justify;
  padding-top: 20px;
}
.cb-para {
  background-color: #c9d8ef;
  box-shadow: 0px 0px 5px;
  border-radius: 20px;
  border-bottom-right-radius: 20%;
  border-top-left-radius: 20%;
  cursor: pointer;
}
.cb-para:hover {
  transform: scale(1.02);
}
.piechart {
  width: 100%;
  height: auto;
  border-radius: 90px;
}
.image-wrap {
  position: relative;
  overflow-x: hidden;
}
.text-obs {
  color: beige;
}
.text-obs:hover {
  color: rgb(179, 187, 194);
}
.banner-content {
  position: absolute;
  /* z-index: 99999; */
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  font-size: 1.5em;
  line-height: 1.5;
}
.banner-content h1 {
  color: #02c5ff;
}
.img-content {
  width: 102%;
  margin-left: -11px;
}

.img-content video {
  width: 100%;
  height: 110%;
  display: block;
  /* background-clip: url('../../../assets/Images/video2.mp4'); */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #4f4e52db;
  opacity: 0.8;
  /* z-index: 999; */
  height: 100%;
}
.banner-btn {
  color: white;
}

@media only screen and (max-width: 768px) {
  .introducation-pa,
  .second-introducation {
    transform: skewX(0deg);
  }
  .intro-d {
    margin-left: 0rem !important;
  }

  .mvo-d {
    flex-direction: column;
    margin-right: 1rem !important;
  }
  .company-story {
    margin: 0px !important;
  }
  .str-img {
    flex-direction: column-reverse;
  }
  .knlogo {
    width: 100%;
    margin-left: -2.5rem;
  }
  .create-history {
    margin-left: 0px !important;
  }
  .ch-pa {
    flex-direction: column;
  }
  .quote-rounded,
  .prologue,
  .focus-para,
  .cb-para {
    margin-left: 10px !important;
  }

  .banner-content {
    top: 60%;
    font-size: 12px;
  }
  .banner-content h1 {
    font-size: 20px;
  }
  .img-content {
    margin-left: -11px;
    width: 105%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1224px) {
  .banner-content {
    top: 60%;
    font-size: 12px;
  }
  .str-img {
    flex-direction: column-reverse;
  }
  .nm {
    width: 100% !important;
  }
  .ab-para {
    text-align: justify !important;
    margin-left: 6rem;
  }
  .knlogo {
    width: 100%;
    margin-left: 12rem;
  }
  .ab-para {
    text-align: left;
  }
  .piechart {
    height: 100px;
    width: 300px;
  }
}
