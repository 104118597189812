.bestsellers-mains {
  margin-top: 60px;
  margin-left: 0px;
  /* border: 1px solid rgb(204, 200, 200); */
  height: 520px;
}

.bestsellers-text {
  font-weight: 600;
  margin-top: 23px;
  margin-bottom: 20px;
  text-align: center;
}
.multiproduct-item {
  height: 420px;
  background: white;
  width: 300px;
  margin-left: 38px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  text-align: center;
}
.img-box {
  height: 225px;
}

.img-box img {
  height: 225px;
}

.cgl {
  width: 236px;
}
#abt {
  background-color: #34d399;
  position: relative;
  left: 31px;
}

.product-card-container {
  transition: transform 300ms ease-in-out;
}
.product-card-container:hover {
  transform: scale(1.06);
}

@media only screen and (max-width: 615px) {
  .bestsellers-text {
    font-size: 1rem;
    margin-top: 10px;
  }
  #abt {
    margin-left: -30px;
  }
  /* .card-img-tops {
    width: 174px;
  } */
  .pimg {
    width: 166px;
  }
  #ptx,
  #htx {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 615px) and (max-width: 768px) {
  .bestsellers-text {
    font-size: 1rem;
    margin-top: 10px;
  }
  #abt {
    margin-left: -30px;
  }
  /* .card-img-tops {
    width: 216px;
  } */
  .pimg {
    width: 166px;
  }
  .ptx,
  .htx {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1014px) {
  .bestsellers-text {
    font-size: 1rem;
    margin-top: 10px;
  }
  /* .card-img-tops {
    width: 291px;
  } */
  #abt {
    margin-left: -30px;
  }
}
@media only screen and (min-width: 1014px) and (max-width: 1400px) {
  /* .card-img-tops {
    width:162px;
  } */
}
