.rrounded {
  border-radius: 6.2rem;
  box-shadow: 0 -5px 3px -3px rgb(21, 13, 13), 0 5px 3px -3px rgb(22, 16, 16);
  border-radius: 50%;
}
.rrounded:hover {
  box-shadow: 0px 1px 10px rgb(104, 255, 197);
}
/* Button radio/checkbox/select */

/* @extend display-flex; */

display-flex,
.form-flex,
.form-row,
.add-info-link {
  display: flex;
  flex-direction: column;
  display: -webkit-flex;
}

/* @extend list-type-ulli; */

#rw-container {
  width: 680px;
  position: relative;
  margin: 0 auto;
  /* box-shadow: 0px 10px 9.9px 0.1px rgba(0, 0, 0, 0.1); */
  background: #fff;
}

.rw-signup-form {
  padding-top: 10px;
  /* margin: 50px; */
}

#country {
  z-index: 99;
  background-color: transparent;
}

option {
  padding: 10px;
}

#two-columns {
  flex-direction: row;
}

#signup-radio {
  flex-direction: row;
  margin-top: 10px;
}

.terms-condition {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#terms {
  width: 50px !important;
  margin-bottom: 6px;
}

.register-group .sends {
  background-color: #34d399;
  width: 100%;
  color: #fff;
  border-radius: 10px;
  /* padding:2px; */
  font-size: 16px;
  border: none;
}
.register-group .sends:hover {
  background-color: #7debc3;
}

#reg-signup {
  width: 50px !important;
  margin-bottom: 6px;
}

.terms-condition label {
  text-align: start;
}

.reg-details {
  padding: 0 0 25px;
  text-align: start;
}

.reg-details .form-input {
  margin-top: 15px;
}

#reg-document .form-control {
  padding: 7px 20px;
}

#signup-insurance {
  /* flex-direction: row; */
  padding: 5px;
  gap: 5px;
  justify-content: space-between;
}

.terms-condition-validation {
  margin-top: -10px;
  padding-bottom: 20px;
}

#signup-insurance div {
  display: flex;
  width: 10%;
}

@media (max-width: 768px) {
  #signup-insurance div {
    display: flex;
    width: 15%;
  }
}

#signup-insurance div label {
  display: flex;
  align-items: center;
}

#signup-radio div {
  width: 20px;
  display: inline-block;
  text-align: start;
}

.rw-signup-form label,
.rw-signup-form input {
  display: block;
  width: 100%;
}

#radio-columns {
  flex-direction: row;
}

.rw-signup-form label {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
}

.select-list select {
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #938f8f;
}

.rw-signup-form input,
.select-list {
  border: 1px solid #3c4378;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  box-sizing: border-box;
  padding: 9px 12px;
  font-size: 14px;
}

.rw-signup-form input:focus {
  border: 1px solid #1da0f2;
}

.rw-signup-form input::-webkit-input-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

.rw-signup-form input::-moz-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

.rw-signup-form input:-ms-input-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

#rw-signup-form input::-webkit-input-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

#rw-signup-form input::-moz-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

#rw-signup-form input:-ms-input-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

#rw-signup-form input:-moz-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

.rw-signup-form input:-moz-placeholder {
  color: #999;
  text-transform: capitalize;
  font-weight: 400;
}

/* 
#rw-signup-form .form-radio {
  margin-bottom: 40px;
} */

#rw-signup-form .form-radio input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

#rw-signup-form .form-radio input + label {
  margin: 0px;
  padding: 9px 12px;
  width: 94px;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  border: 1px solid #ebebeb;
  background-color: rgb(230, 220, 220);
  font-size: 14px;
  font-weight: 600;
  color: #888;
  text-align: center;
  text-transform: none;
  transition: border-color 0.15s ease-out, color 0.25s ease-out,
    background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
}

#rw-signup-form .form-radio input:checked + label {
  background-color: #1da0f2;
  color: #fff;
  border-color: #1da0f2;
  z-index: 1;
}

#rw-signup-form .form-radio input:focus + label {
  outline: none;
}

#rw-signup-form .form-radio input:hover {
  background-color: #1da0f2;
  color: #fff;
  border-color: #1da0f2;
}

#rw-signup-form .form-flex input + label:first-of-type {
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  border-right: none;
}

#rw-signup-form .form-flex input + label:last-of-type {
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
  -ms-border-radius: 0 5px 5px 0;
  border-left: none;
}

#rw-signup-form .form-row {
  margin: 0 -11px;
}

.rw-signup-form .form-flex input + label:first-of-type {
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  -ms-border-radius: 5px 0 0 5px;
  border-right: none;
}

.rw-signup-form .form-flex input + label:last-of-type {
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
  -ms-border-radius: 0 5px 5px 0;
  border-left: none;
}

.rw-signup-form .form-row {
  margin: 0 -11px;
}

.form-row .form-group,
.form-row .form-radio {
  width: 100%;
  padding: 10px 11px;
  text-align: start;
}

.rw-signup-form .form-group,
.form-radio {
  margin-bottom: 5px;
  position: relative;
}

#rw-signup-form .form-group label {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  #rw-container {
    width: calc(100% - 30px);
    max-width: 100%;
  }
  #two-columns {
    flex-direction: column;
  }
  .reg-details .row {
    flex-direction: column;
    gap: 20px;
  }
}
