.banner-contain {
  /* background: rgb(200, 211, 232); */
  padding: 150px 0px 0px 0px;
  background-image: linear-gradient(25deg, #5e50a9 47%, lightblue 67%);
}
.banner1 {
  /* width: auto; */
  /* padding:5px; */
  object-fit: cover;
  margin-left: 3px;
  box-shadow: 0px 0px 15px #504e4e;
  height: 290px;
}

.banner-subimage {
  /* height: 100%; */
  /* width: 100%; */
  border: 5px solid white;
  /* animation: animName 10.5s linear infinite; */
}

/* @keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.bv {
  width: 100% !important;
  height: 100%;
}

@media only screen and (max-width: 615px) {
  .banner-contain {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 615px) and (max-width: 768px) {
  /* .bv {
    width: 640px;
  } */
  .banner-contain {
    flex-direction: column;
  }
  /* .banner-subimage {
    height: 90%;
    width: 640px;
  } */
  .sub-banner1,
  .sub-banner2 {
    width: 640px;
  }
  .sub-banner-image {
    width: 640px;
    height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 998px) {
  /* .bv {
    width: 740px;
  } */
  .banner-main {
    display: flex;
    /* flex-direction: column; */
  }
  /* .banner-subimage {
    height: 90%;
    width: 740px;
  } */
  .sub-banner1,
  .sub-banner2 {
    width: 740px;
  }
  .sub-banner-image {
    width: 740px;
    height: 150px;
  }
}

@media only screen and (min-width: 998px) and (max-width: 1179px) {
  /* .bv {
    width: 240px;
  } */
  .banner-main {
    display: flex;
  }
  /* .banner-subimage {
    height: 90%;
    width: 240px;
  } */
  /* .sub-banner1,
  .sub-banner2 {
    width: 240px;
  }
  .sub-banner-image {
    width: 322px;
    height: 289px;
  } */
}

@media only screen and (min-width: 998px) and (max-width: 1179px) {
  .bv {
    width: 240px;
  }
  .banner-main {
    display: flex;
  }
  /* .banner-subimage {
    height: 90%;
    width: 240px;
  } */
  .sub-banner1,
  .sub-banner2 {
    width: 240px;
  }
  .sub-banner-image {
    width: 322px;
    height: 289px;
  }
}
