#product-details,#order-details,#other-details{
    display: flex;
   flex-direction: row;
   flex-wrap: wrap;
}
#product-details .card{
    background:#17a2b8;
}
#product-details h2 ,#order-details h2,#other-details h2{
    font-weight: bold;
    color: white;
}
#order-details .card{
    background: #df2572;  
}

#other-details .card{
    background: #42a93c;
}