.menu__items,
.menu-open-button {
  background: #eeeeee;
  border-radius: 100%;
  width: 37px;
  height: 37px;
  position: absolute;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform ease-out 200ms;
  transition: -webkit-transform ease-out 200ms;
  transition: transform ease-out 200ms;
  transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-open {
  display: none;
}

.menus__ {
  position: absolute;
  right: 110px;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  /* font-size: 26px; */
}
@media only screen and (max-width: 600px) {
  .menu__items,
  .menu-open-button {
    margin-top: 10px;
  }
}

.share-icon {
  color: #596778;
  /*background color*/
}

.menu__items:hover {
  background: #eeeeee;
  color: #3290b1;
}

.menu__items:nth-child(3) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu__items:nth-child(4) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu__items:nth-child(5) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu__items:nth-child(6) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu__items:nth-child(7) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu__items:nth-child(8) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu__items:nth-child(9) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
}

.menu-open-button:hover {
  -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  transform: scale(0.9, 0.9) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu__items {
  -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked ~ .menu__items:nth-child(3) {
  transition-duration: 180ms;
  -webkit-transition-duration: 180ms;
  -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
  transform: translate3d(0px, -60.99997px, 0);
}

.menu-open:checked ~ .menu__items:nth-child(4) {
  transition-duration: 280ms;
  -webkit-transition-duration: 280ms;
  -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
  transform: translate3d(63.9466px, -2.47586px, 0);
}

.menu-open:checked ~ .menu__items:nth-child(5) {
  transition-duration: 380ms;
  -webkit-transition-duration: 380ms;
  -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
  transform: translate3d(5.9466px, 64.47586px, 0);
}

.menu-open:checked ~ .menu__items:nth-child(6) {
  transition-duration: 480ms;
  -webkit-transition-duration: 480ms;
  -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
  transform: translate3d(0.08361px, 104.99997px, 0);
}

.menu-open:checked ~ .menu__items:nth-child(7) {
  transition-duration: 580ms;
  -webkit-transition-duration: 580ms;
  -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
  transform: translate3d(-90.86291px, 52.62064px, 0);
}

.menu-open:checked ~ .menu__items:nth-child(8) {
  transition-duration: 680ms;
  -webkit-transition-duration: 680ms;
  -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
  transform: translate3d(-91.03006px, -52.33095px, 0);
}

.menu-open:checked ~ .menu__items:nth-child(9) {
  transition-duration: 780ms;
  -webkit-transition-duration: 780ms;
  -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
  transform: translate3d(-0.25084px, -104.9997px, 0);
}

.facebook_share_btn {
  background-color: #3b5998;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.facebook_share_btn:hover {
  color: #3b5998;
  text-shadow: none;
}

.twitter_share_btn {
  background-color: #00aced;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.twitter_share_btn:hover {
  color: #00aced;
  text-shadow: none;
}

.google_plus_share_btn {
  background-color: #dd4b39;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.google_plus_share_btn:hover {
  color: #dd4b39;
  text-shadow: none;
}

.youtube_share_btn {
  background-color: #bb0000;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.youtube_share_btn:hover {
  color: #bb0000;
  text-shadow: none;
}

.pinterest_share_btn {
  background-color: #cb2027;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.pinterest_share_btn:hover {
  color: #cb2027;
  text-shadow: none;
}

.tumblr_share_btn {
  background-color: #32506d;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.tumblr_share_btn:hover {
  color: #32506d;
  text-shadow: none;
}

/*redesigner*/

.redesigner {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-decoration: none;
  letter-spacing: 1.5px;
}

.redesigner a:nth-child(1) {
  text-decoration: none;
  transition: all 0.5s;
}

.redesigner a:nth-child(1):hover {
  text-decoration: none;
  letter-spacing: 5px;
  transition: all 0.5s;
}

.redesigner a:nth-child(3) {
  letter-spacing: normal;
  font-size: 10px;
  text-decoration: none;
}

.redesigner a:nth-child(3):hover {
  text-decoration: none;
}
