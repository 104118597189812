/*  text-editor2*/
.text-editor1 {
  min-height: 200px !important;
  border: 1px solid #48b18c;
}
.text-editor2 {
  min-height: 500px !important;
  border: 1px solid #48b18c;
}
/*  text-editor2*/

.d-grid {
  margin: 30px;
}
input:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #48b18c;
  outline-width: 0;
}

.text-header0 {
  background-color: #dfebe5 !important;
  text-transform: capitalize;
}

#panel {
  /* padding: 15px; */
  display: none;
}
#panel1 {
  padding: 15px;
  display: none;
}
.more1:hover {
  color: #51e1bf;
}
.more1 {
 text-transform: capitalize;
}
