.react-slider__picture {
  height: 378px;
}

.flash-sale-box {
  height: 50px;
  color: black;
  font-size: 20px;
  font-weight: 700;
  background-color: #fcbd34;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.product-container {
  margin-top: 103px;
}

.custom-control-label {
  margin-left: 2px;
}

#product-description {
  text-align: start;
}

.product-content {
  border: 1px solid #dfe5e9;
  margin-bottom: 20px;
  /* margin-top: 12px; */
  padding-top: 50px !important;
  background: #fff;
}

.product-content .product-deatil {
  border-bottom: 1px solid #dfe5e9;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-top: 16px;
  position: relative;
  background: #fff;
}

.product-content .product-deatil h5 a {
  color: #2f383d;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
  padding-left: 0;
  margin-left: 0;
}

.product-content .product-deatil h5 a span {
  color: #9aa7af;
  display: block;
  font-size: 13px;
}

.product-content .product-deatil span.tag1 {
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  height: 50px;
  padding: 13px 0;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 50px;
}

.product-content .description {
  font-size: 12.5px;
  line-height: 20px;
  padding: 10px 14px 16px 19px;
  background: #fff;
}

.product-content .product-info {
  padding: 11px 19px 10px 20px;
}

.product-content .product-info .a.add-to-cart {
  color: #2f383d;
  font-size: 13px;
  padding-left: 16px;
}

.product-content name.a {
  padding: 5px 10px;
  margin-left: 16px;
}

.product-info.smart-form .btn {
  padding: 6px 12px;
  margin-left: 12px;
  margin-top: -10px;
}

.product-entry .product-deatil {
  border-bottom: 1px solid #dfe5e9;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-top: 16px;
  position: relative;
}

.product-entry .product-deatil h5 a {
  color: #2f383d;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
}

.product-entry .product-deatil h5 a span {
  color: #9aa7af;
  display: block;
  font-size: 13px;
}

.load-more-btn {
  background-color: #21c2f8;
  border-bottom: 2px solid #037ca5;
  border-radius: 2px;
  border-top: 2px solid #0cf;
  margin-top: 20px;
  padding: 9px 0;
  width: 100%;
}

.product-block .product-deatil p.price-container span,
.product-content .product-deatil p.price-container span,
.product-entry .product-deatil p.price-container span,
.shipping table tbody tr td p.price-container span,
.shopping-items table tbody tr td p.price-container span {
  color: #21c2f8;
  font-family: Lato, sans-serif;
  font-size: 24px;
  line-height: 20px;
}

.product-info.smart-form .rating label {
  margin-top: 0;
}

#product-description span {
  margin: 0 7px;
}

.product-wrap .product-image span.tag2 {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 10px 0;
  color: #fff;
  font-size: 11px;
  text-align: center;
}

.li {
  margin-left: 8px;
  width: 156px;
  height: 43px;
  padding-top: 10px;
  background: #dfe5e9;
}
.li a {
  text-decoration: none;
  width: 100px;
}
.nav {
  margin-left: 123px;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.description-tabs {
  padding: 30px 0 5px !important;
}

.description-tabs .tab-content {
  padding: 10px 0;
}

.product-deatil {
  padding: 30px 30px 50px;
}

.product-deatil hr + .description-tabs {
  padding: 0 0 5px !important;
}

.product-deatil .carousel-control.left,
.product-deatil .carousel-control.right {
  background: none !important;
}

.product-deatil .product-image {
  border-right: none !important;
}

.product-deatil .name {
  margin-top: 0;
  margin-bottom: 0;
}

.product-deatil .name small {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

.product-deatil .name a {
  margin-left: 0;
  margin-top: 25px;
}

.product-deatil .price-container {
  font-size: 22px;
  margin: 25px;
  font-weight: 300;
}

.specs {
  padding: 10px;
  /* border-radius: 10px; */
  /* box-shadow: 5px 5px 10px whitesmoke; */
}

.specs-items {
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  font-weight: 400 !important;
}

.specs-title {
  width: 180px;
}

.product-deatil .price-container small {
  font-size: 14px;
  margin: 0 10px;
}

.product-deatil .fa-2x {
  font-size: 16px !important;
}

.product-deatil .fa-2x > h5 {
  font-size: 14px;
  margin: 0;
}

.product-deatil .fa-2x + a,
.product-deatil .fa-2x + a + a {
  font-size: 13px;
}

.profile-message ul {
  list-style: none;
}

.product-deatil .certified {
  margin-top: 10px;
}

.product-deatil .certified ul {
  padding-left: 0;
  display: flex;
  gap: 40px;
}

.product-deatil .certified ul li:not(first-child) {
  margin-left: -3px;
}

.product-deatil .certified ul li {
  display: inline-block;
  background-color: #f9f9f9;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  box-shadow: 6px 6px 6px whitesmoke;
  padding: 13px 19px;
}

.product-deatil .certified ul li:first-child {
  border-right: none;
}

.product-deatil .certified ul li a {
  text-align: left;
  font-size: 12px;
  color: #6d7a83;
  line-height: 16px;
  text-decoration: none;
}

.product-deatil .certified ul li a span {
  display: block;
  color: #21c2f8;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}
#variant {
  margin-top: 60px;
  width: 350px;
}

.add-to-cart-btn {
  margin-top: 10px;
  margin-left: 60px;
  /* text-align: center; */
}
.add-to-cart-btn button {
  background-color: #20c997;
  padding: 5px;
  font-size: 16px;
  color: white;
  border: none;
}
.add-to-cart-btn button:hover {
  background-color: aquamarine;
  color: black;
  border: none;
}

.product-deatil .message-text {
  width: calc(100% - 70px);
}
.sbtn {
  position: relative;
  left: 397px;
  top: 31px;
  width: 163px;
  font-size: 11px;
}
.sbtn:hover {
  background-color: #20c997;
}

.product-img-container {
  height: 350px;
  position: sticky !important;
  top: 4px;
  width: 100%;
  /* margin-bottom: 80px; */
}

.product-mini-img {
  position: sticky !important;
  top: 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  height: max-content;
}

.product-description-container {
  border-radius: 10px;
  margin: 20px 80px 0 0;
  text-align: justify;
}

.messagebox-product {
  text-align: start;
  margin-top: 50px;
}

.message-header {
  display: flex;
  justify-content: space-between;
}

.message-text {
  margin: 10px 0px;
  text-align: start;
}
/* .imgBox {
  display: flex !important;
} */
/* .imgBox {
  position: sticky;
} */

#samples {
  /* height: 500px; */
  text-align: center;
  display: flex;
  justify-content: center;
  position: sticky;
}

#samples #zimg {
  width: 65px;
  height: 65px;
  margin: 4px;
  display: block;
  border: 2px solid #f8d7f0;
}
.pre {
  height: 200px;
  position: relative;
  bottom: 442px;
  left: 112px;
}

ul.thumb {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  float: left;
}
ul.thumb li {
  list-style: none;
  margin: 0 10px;

  overflow: hidden;
}

.thumb {
  margin-bottom: 5px;
  width: 100px;
  height: 100px;
}

.magnifier {
  margin-bottom: 5px;
  width: 274px;
  height: 299px;
  /* margin-right: 110px; */
  cursor: zoom-in;
}

.magnifier:hover {
  box-shadow: 0 0 5px rgb(218, 213, 205);
}
.magnifier:hover #preview {
  transform: skew(1, 3);
}
#preview {
  display: none;
  /* margin-left: 710px;
  margin-top: -140px; */
  width: 200px;
  height: 200px;

  /* border: 3px solid orange; */
}

#cursor-overlay {
  display: none;
  background-color: rgba(0, 150, 50, 0.5);
  position: fixed;
  pointer-events: none;
}

.imgBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 615px) {
  #samples {
    height: 295px;
    text-align: center;
  }
  .iB {
    width: 150px;
  }
  .magnifier {
    width: 200px;
    height: 242px;
    margin-left: 89px;
    margin-top: -76px;
  }
  #preview {
    margin-left: 399px;
    margin-top: -180px;
    width: 139px;
    height: 100px;
  }
  .nav {
    margin-left: 0px;
  }
  .product-img-container {
    height: 350px;
    margin-bottom: 30px;
  }

  .product-mini-img {
    display: flex;
  }
  .product-deatil-head {
    margin-top: 40px;
  }

  .add-to-cart-btn {
    margin-left: 0px;
    /* text-align: center; */
  }
  .product-description-container {
    margin: 20px 40px 0 0;
  }
}
@media screen and(min-width:760px) and (max-width: 819px) {
  .magnifier {
    margin-right: 133px;
  }

  .product-img-container {
    margin-bottom: 30px;
    position: relative !important;
  }

  .product-mini-img {
    display: flex;
    position: relative !important;
  }
  .product-deatil-head {
    margin-top: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .add-to-cart-btn {
    margin-left: 0px;
    /* text-align: center; */
  }
}
.product-description-container {
  margin: 20px 40px 0 0;
}
/* @media screen and (min-width:819px) and (max-width:900px){
       .magnifier{
        margin-right:233px;
       }
} */
@media screen and (max-width: 1000px) {
  .navbar-main {
    display: none;
  }
  .product-img-container {
    position: relative !important;
  }

  .product-mini-img {
    display: flex;
  }
  .product-deatil-head {
    position: relative;
    margin-top: 40px;
  }

  .add-to-cart-btn {
    margin-left: 0px;
    /* text-align: center; */
  }
  .product-description-container {
    margin: 20px 0px 0 0;
  }
}
@media screen and (min-width: 400px) and (max-width: 618px) {
  .react-slider {
    width: 175% !important;
  }
  .react-slider__container {
    width: 175% !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 520px) {
  .react-slider {
    /* margin-left:-2rem !important; */
  }
  .react-slider__container {
    margin-left: -2rem !important;
    width: 145% !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 400px) {
  .react-slider {
    /* margin-left:-2rem !important; */
  }
  .react-slider__container {
    margin-left: -2rem !important;
    width: 195% !important;
  }
}
