.bestsellers-main {
  /* background: rgb(200, 211, 232); */
  height: auto;
  /* background-image: linear-gradient(25deg, #5e50a9 46%,  lightblue 67%); */
  /* background-color: #f0f2f5; */
  background-color: #e2e6eb;
}
.bestsellers-main .card-group {
  height: 440px;
  padding: 1px;
  transition: transform 5.2ms ease-in-out;
  /* box-shadow: 3px 3px 12px #4f4e56; */
}
.grid_gap__product_ {
  padding: 3px !important;
}
.bestsellers-main .card-group:hover {
  /* transform: scale(1.02); */
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.product__effect_ {
  transition: transform 5.2ms ease-in-out;
}
.product__effect_:hover {
  /* transform: scale(1.02); */
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.bestsellers-text {
  font-weight: 600;
  margin-top: 23px;
  text-align: center;
}
.multiproduct-item {
  height: 420px;
  background: white;
  width: 261px;
  margin-left: 38px;
  /* box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 6px;
  text-align: center;
}
.img-box {
  height: 225px;
}

.img-box img {
  height: 225px;
}

.rec .react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: visible !important;
  position: relative;
}

.react-multi-carousel-item {
  right: 5px;
  /* width:254.5px !important; */
}

@media only screen and (max-width: 615px) {
  .bestsellers-text {
    font-size: 1rem;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 615px) and (max-width: 768px) {
  .bestsellers-text {
    font-size: 1rem;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 998px) {
  .bestsellers-text {
    font-size: 1rem;
    margin-top: 10px;
  }
}

/* -------------------------------------------------------------------------- */
/*                                    card                                    */
/* -------------------------------------------------------------------------- */

.view__buttons_ {
  display: flex;
  justify-content: flex-end;
}

.view__buttons_ button {
  font-size: 0.9em;
  padding: 0.4em 1.5em;
  border-radius: 6px;
}

.sales__product {
  cursor: pointer;
  /* height: 16em; */
  padding: 0em 1em;
  margin: 0.1em 0.2em;
  /* width: 13em; */
  box-sizing: border-box;
  border: 0;
}

.sales__product img {
  height: 60%;
  transform: scale(0.97);
  transition: 0.5s;
}

.sales__product img:hover {
  transform: scale(1.1);
}

.sales__product a {
  text-decoration: none;
  color: #388e3c;
  font-size: 0.95em;
}

.sales__product h6 {
  color: black;
  /* font-size: 1.1em; */
}

.sales__product p {
  color: red;
  /* opacity: 0.8; */
  font-size: 14px;
  margin-top: 0.6em;
}

/* Media Queries */
@media screen and (max-width: 576px) {
  .slider-heading h4 {
    font-size: 1.2em;
    margin-bottom: 0;
  }

  .view__buttons_ button {
    font-size: 0.8em;
    padding: 0;
    border-radius: 0;
  }

  .login,
  .more {
    padding: 0.2em 0.4em;
  }

  .sales__product img:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 992px) {
  .view__buttons_ button {
    font-size: 0.8em;
    padding: 0.4em 1.1em;
    border-radius: 0;
  }
}
