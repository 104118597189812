.news__blog_notice_clr {
  text-transform: uppercase;
  color: white;
  background: linear-gradient(
    40deg,
    #981d97,
    #da1884,
    #ee5a5e,
    #ed8b00,
    #ffc658
  );
  /* font-family: "Avant Garde Gothic", "Century Gothic", "CenturyGothic",
    "AppleGothic", sans-serif; */
  font-size: 35px;
  font-weight: 1000;
  line-height: 1.05;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.news__blog_notice__color_ {
  color: white;
  background: linear-gradient(
    40deg,
    /* #981d97,
    #da1884,
    #ee5a5e,
    #ed8b00,
    #ffc658 */ #3b4d61,
    #6b7b8c
  );
  /* font-family: "Avant Garde Gothic", "Century Gothic", "CenturyGothic",
    "AppleGothic", sans-serif; */
  /* font-size: 25px; */
  font-weight: 1000;
  line-height: 1.05;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.news__blog_notice_blog {
  text-transform: uppercase;
  color: white;
  background: linear-gradient(
    40deg,
    #981d97,
    #da1884,
    #ee5a5e,
    #ed8b00,
    #ffc658
  );
  /* font-family: "Avant Garde Gothic", "Century Gothic", "CenturyGothic",
    "AppleGothic", sans-serif; */
  font-size: 23px;
  font-weight: 1000;
  line-height: 1.05;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.news__blog_notice_title {
  text-transform: uppercase;
  color: white;
  background: linear-gradient(
    40deg,
    #981d97,
    #da1884,
    #ee5a5e,
    #ed8b00,
    #ffc658
  );
  /* font-family: "Avant Garde Gothic", "Century Gothic", "CenturyGothic",
    "AppleGothic", sans-serif; */
  font-size: 20px;
  font-weight: 1000;
  line-height: 1.05;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.news__text__ {
  font-size: 1.53rem;
  font-weight: bold;
  text-transform: capitalize;
}
/* .news__text__:hover {
  color: white;
} */
@media screen and (max-width: 768px) {
  .news__blog_notice_clr {
    font-size: 25px;
  }
}
/* --------------------------------- cards --------------------------------- */
.card__text__news {
  height: 220;
  background: #f1f1f1;
}
.news__hover_effect:hover {
  /* background: #981d97; */
  background: rgb(46, 85, 161);
  cursor: pointer;
  /* box-shadow: 2px 0px 2px; */
  transition: 500ms ease-in;
}
.news__hover_effect:hover .news__text__ {
  color: white !important;
}

.node__tag {
  position: absolute;
  z-index: 1;
  top: -5px;
  /* right: -5px; */
  right: 0;
  padding: 0.6em 1em;
  color: white;
  background-color: #3f6eb0;
  /* background-color: #981d97; */
  font-size: 0.625rem;
  text-align: center;
}
