/* custom-animation.css */
@keyframes customEnterOverlayAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveOverlayAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes customEnterModalAnimation {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes customLeaveModalAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
  }
}
/* .custom-height{
  width: 800px;
  height: 500px;
} */

.customModal {
  max-width: 600px;
  background: #f1f1f1;
  width: 100%;
}
#m-color{
  background: #cad7d7;
    text-align: center;
    padding: 4px 0 4px 9px;
    border-radius: 5px;
    margin: 2px;
}
#user-details{
  background: #cad7d7;
    padding: 4px;
    border-radius: 2px;
}